import get from 'lodash/get'
import { createAsyncThunk } from '@reduxjs/toolkit'
import type { SearchResponse, SearchOptions } from '@algolia/client-search'

import type { ThunkAPI } from 'store/store.types'

import {
  buildAvailabilityFilters,
  buildMaxOccupancyFilter,
  buildMinimumStayFilters,
  buildPriceFilters,
  stringifyBoundingBox,
} from './search.utils'
import searchSlice from './search.reducer'

import {
  augmentedSearchIndex,
  buildFacetFilters,
  buildNumericFilters,
  filterBuilder,
} from 'config/AlgoliaClient'

import urlToSearchState from 'utils/search/urlToSearchState'

import FILTER_ATTRIBUTES from 'constants/filterAttributes'

export const backgroundSearch = createAsyncThunk<number, void, ThunkAPI>(
  'search/backgroundSearch',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState()
    // Algolia expects the wrong type for facetFilters
    const query = get(state, 'search.query', '')
    const {
      selectedAmenities: amenity,
      selectedAccessibility: accessibility,
      selectedPropertyTypes: propertyType,
      selectedLocationAmenities: location,
      selectedViewAmenities: view,
      selectedMinBeds: minBeds,
      selectedMinBathrooms: minBathrooms,
      selectedMinBedrooms: minBedrooms,
      selectedGuests,
      selectedDates,
    } = state.search
    const minPrice = get(state, 'search.selectedMinPrice', 0)
    const maxPrice = get(state, 'search.selectedMaxPrice', 0)
    const checkIn = get(selectedDates, 'start', undefined)
    const checkOut = get(selectedDates, 'end', undefined)
    const beds = minBeds ? { gte: minBeds } : undefined
    const bathrooms = minBathrooms ? { gte: minBathrooms } : undefined
    const bedrooms = minBedrooms ? { gte: minBedrooms } : undefined
    const maxOccupancy = buildMaxOccupancyFilter(selectedGuests)
    const availability = buildAvailabilityFilters(checkIn, checkOut)
    const minStay = buildMinimumStayFilters(checkIn, checkOut)
    const [priceFilterKey, priceFilter] = buildPriceFilters(
      checkIn,
      checkOut,
      minPrice,
      maxPrice,
    )
    const filters = filterBuilder({
      minStay,
      availability,
      [priceFilterKey]: priceFilter,
    })
    const facetFilters = buildFacetFilters({
      amenity,
      accessibility,
      propertyType: [
        {
          values: propertyType,
        },
      ],
      location: [
        {
          values: location,
        },
      ],
      view: [
        {
          values: view,
        },
      ],
    })

    const numericFilters = [
      ...buildNumericFilters({
        bedrooms,
        maxOccupancy,
        bathrooms,
        beds,
      }),
    ]

    const boundingBox =
      (process.browser &&
        urlToSearchState(window.location.search)?.boundingBox) ??
      undefined

    const searchRequest: SearchOptions = {
      attributesToRetrieve: [FILTER_ATTRIBUTES.OBJECT_ID],
      analytics: false,
      clickAnalytics: false,
      percentileComputation: false,
      attributesToHighlight: [],
      facets: [
        FILTER_ATTRIBUTES.ACCESSIBILITY,
        FILTER_ATTRIBUTES.ACCESSIBILITY,
        FILTER_ATTRIBUTES.PROPERTY_TYPE,
        FILTER_ATTRIBUTES.MAX_OCCUPANCY,
        FILTER_ATTRIBUTES.BEDS,
        FILTER_ATTRIBUTES.BEDROOMS,
        FILTER_ATTRIBUTES.BATHROOMS,
        FILTER_ATTRIBUTES.AVG_PER_NIGHT,
        FILTER_ATTRIBUTES.LOCATION,
        FILTER_ATTRIBUTES.VIEW,
      ],
      facetFilters:
        facetFilters && facetFilters.length ? facetFilters : undefined,
      numericFilters: numericFilters.length ? numericFilters : undefined,
      aroundRadius: state.search.searchParameters.aroundRadius,
      aroundPrecision: state.search.searchParameters.aroundPrecision,
      filters,
      insideBoundingBox: stringifyBoundingBox(boundingBox),
    }
    const results: SearchResponse<unknown> = await augmentedSearchIndex.search(
      // Ignore query if boundingBox is present
      boundingBox ? '' : query,
      searchRequest,
    )

    return results.nbHits
  },
)

export const filterAmenities = createAsyncThunk<void, string[], ThunkAPI>(
  'search/filterAmenities',
  async (filters, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedAmenities(filters))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterLocationAmenities = createAsyncThunk<
  void,
  string[],
  ThunkAPI
>('search/filterLocationAmenities', async (filters, thunkAPI) => {
  thunkAPI.dispatch(searchSlice.actions.setSelectedLocationAmenities(filters))
  thunkAPI.dispatch(backgroundSearch())
})

export const filterViewAmenities = createAsyncThunk<void, string[], ThunkAPI>(
  'search/filterViewAmenities',
  async (filters, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedViewAmenities(filters))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterAccessibility = createAsyncThunk<void, string[], ThunkAPI>(
  'search/filterAccessibility',
  async (filters, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedAccessibility(filters))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterPropertyTypes = createAsyncThunk<void, string[], ThunkAPI>(
  'search/filterPropertyTypes',
  async (filters, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedPropertyTypes(filters))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterActivities = createAsyncThunk<void, string[], ThunkAPI>(
  'search/filterActivities',
  async (filters, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedActivities(filters))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterMinBeds = createAsyncThunk<void, number, ThunkAPI>(
  'search/filterMinBeds',
  async (beds, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedMinBeds(beds))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterMinBathrooms = createAsyncThunk<void, number, ThunkAPI>(
  'search/filterMinBathrooms',
  async (bathrooms, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedMinBathrooms(bathrooms))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterMinBedrooms = createAsyncThunk<void, number, ThunkAPI>(
  'search/filterMinBedrooms',
  async (bedrooms, thunkAPI) => {
    thunkAPI.dispatch(searchSlice.actions.setSelectedMinBedrooms(bedrooms))
    thunkAPI.dispatch(backgroundSearch())
  },
)

export const filterPrice = createAsyncThunk<
  void,
  { minPrice?: number; maxPrice?: number },
  ThunkAPI
>('search/filterPrice', async ({ minPrice, maxPrice }, thunkAPI) => {
  thunkAPI.dispatch(searchSlice.actions.setSelectedMinPrice(minPrice))
  thunkAPI.dispatch(searchSlice.actions.setSelectedMaxPrice(maxPrice))
  thunkAPI.dispatch(backgroundSearch())
})

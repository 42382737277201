import { useContext, useCallback } from 'react'

import {
  ConsentContext,
  ConsentContextActionType,
} from 'context/ConsentContext'

const useConsentManager = () => {
  const {
    state: { showConsentModal },
    dispatch,
  } = useContext(ConsentContext)

  const toggleConsentModal = useCallback(() => {
    dispatch({
      type: ConsentContextActionType.SET_SHOW_CONSENT_MODAL,
      payload: !showConsentModal,
    })
  }, [dispatch, showConsentModal])

  return { toggleConsentModal }
}

export default useConsentManager

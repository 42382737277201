import { useEffect } from 'react'

// Redux
import store from 'store/index'

// Utils
import { saveFavoritesState } from 'utils/localStorage'

// Save necessary favorites state to local storage to use as redux preloadedState
export default function useSaveFavoritesState(): null {
  useEffect(() => {
    const handleSaveFavoritesState = () => {
      const {
        selectedListings,
        saveAsGuest,
        showFavoriteNotification,
        favoritesPageViewed,
        currentCollectionId,
        saveAsAuth,
      } = store.getState().favorites
      // saveAsAuth is needed because session will always be undefined in this hook
      if (saveAsGuest || saveAsAuth) {
        const collectionId = saveAsAuth ? currentCollectionId : ''
        saveFavoritesState(
          selectedListings,
          saveAsGuest.toString(),
          showFavoriteNotification.toString(),
          favoritesPageViewed.toString(),
          collectionId,
        )
      }
    }
    window.addEventListener('beforeunload', handleSaveFavoritesState)
    window.addEventListener('visibilitychange', handleSaveFavoritesState)

    return () => {
      window.removeEventListener('beforeunload', handleSaveFavoritesState)
      window.removeEventListener('visibilitychange', handleSaveFavoritesState)
    }
  }, [])

  return null
}

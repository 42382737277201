import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { Hit } from 'react-instantsearch-core'

export interface UIState {
  width: number
  height: number
  datePickerFocused: boolean
  guestPickerFocused: boolean
  mobileSearchBoxFocused: boolean
  mapInfowindowClicked: string
  selectedHit: Hit['objectID']
  activeView: 'grid' | 'map' | 'both'
  mobileFiltersOpen: boolean
  desktopFilterOpen: boolean
  showMoreAmenities: boolean
  showMoreLocationAmenities: boolean
  showMoreViewAmenities: boolean
  showMorePropertyTypes: boolean
  showMoreAccessibility: boolean
  showMoreActivites: boolean
  loginPromptModalOpen: boolean
  loginPromptModalOrigin: string
  hideConsentModal: boolean
  showPreferencesModal: boolean
}

const uiStateSlice = createSlice({
  name: 'uiState',
  initialState: {
    width: 0,
    height: 0,
    datePickerFocused: false,
    guestPickerFocused: false,
    mobileSearchBoxFocused: false,
    mapInfowindowClicked: '',
    selectedHit: '',
    activeView: 'grid',
    mobileFiltersOpen: false,
    desktopFilterOpen: false,
    showMoreAmenities: false,
    showMoreLocationAmenities: false,
    showMoreViewAmenities: false,
    showMorePropertyTypes: false,
    showMoreAccessibility: false,
    showMoreActivites: false,
    loginPromptModalOpen: false,
    loginPromptModalOrigin: '',
    hideConsentModal: false,
    showPreferencesModal: false,
  } as UIState,
  reducers: {
    setWidth: (state, action: PayloadAction<number>) => {
      state.width = action.payload
    },
    setHeight: (state, action: PayloadAction<number>) => {
      state.height = action.payload
    },
    setActiveView: (state, action: PayloadAction<'grid' | 'map' | 'both'>) => {
      state.activeView = action.payload
    },
    openDatePicker: (state) => {
      state.datePickerFocused = true
    },
    closeDatePicker: (state) => {
      state.datePickerFocused = false
    },
    setGuestPickerFocused: (state, action: PayloadAction<boolean>) => {
      state.guestPickerFocused = action.payload
    },
    setMobileSearchBoxFocused: (state, action: PayloadAction<boolean>) => {
      state.mobileSearchBoxFocused = action.payload
    },
    setMapInfowindowClicked: (state, action: PayloadAction<string>) => {
      state.mapInfowindowClicked = action.payload
    },
    setSelectedHit: (state, action: PayloadAction<Hit['objectID']>) => {
      state.selectedHit = action.payload
    },
    setMobileFiltersOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileFiltersOpen = action.payload
    },
    setDesktopFilterOpen: (state, action: PayloadAction<boolean>) => {
      state.desktopFilterOpen = action.payload
      if (state.width <= 900 && action.payload) state.mobileFiltersOpen = true
      else if (state.mobileFiltersOpen && action.payload)
        state.mobileFiltersOpen = false
    },
    setShowMoreAmenities: (state, action: PayloadAction<boolean>) => {
      state.showMoreAmenities = action.payload
    },
    setShowMoreLocationAmenities: (state, action: PayloadAction<boolean>) => {
      state.showMoreLocationAmenities = action.payload
    },
    setShowMoreViewAmenities: (state, action: PayloadAction<boolean>) => {
      state.showMoreViewAmenities = action.payload
    },
    setShowMorePropertyTypes: (state, action: PayloadAction<boolean>) => {
      state.showMorePropertyTypes = action.payload
    },
    setShowMoreAccessibility: (state, action: PayloadAction<boolean>) => {
      state.showMoreAccessibility = action.payload
    },
    setShowMoreActivities: (state, action: PayloadAction<boolean>) => {
      state.showMoreActivites = action.payload
    },
    setShowLoginPromptModal: (state, action: PayloadAction<boolean>) => {
      state.loginPromptModalOpen = action.payload
    },
    setLoginPromptModalOrigin: (state, action: PayloadAction<string>) => {
      state.loginPromptModalOrigin = action.payload
    },
    setBodyStyle: (_, action: PayloadAction<any>) => {
      const [prop, value] = Object.entries(action.payload)[0]
      document.body.style[prop] = value
    },
    setHideConsentModal: (state, action: PayloadAction<boolean>) => {
      state.hideConsentModal = action.payload
    },
    setShowPreferencesModal: (state, action: PayloadAction<boolean>) => {
      state.showPreferencesModal = action.payload
    },
  },
})

export const {
  setWidth,
  setHeight,
  setActiveView,
  openDatePicker,
  closeDatePicker,
  setGuestPickerFocused,
  setMobileSearchBoxFocused,
  setMapInfowindowClicked,
  setSelectedHit,
  setMobileFiltersOpen,
  setDesktopFilterOpen,
  setShowMoreAmenities,
  setShowMoreLocationAmenities,
  setShowMoreViewAmenities,
  setShowMorePropertyTypes,
  setShowMoreAccessibility,
  setShowMoreActivities,
  setShowLoginPromptModal,
  setLoginPromptModalOrigin,
  setBodyStyle,
  setHideConsentModal,
  setShowPreferencesModal,
} = uiStateSlice.actions

export default uiStateSlice

import { createSelector } from '@reduxjs/toolkit'

import { LargeTabletBreakpoint } from 'config/Breakpoints'

const selectWidth = (state) => state.uiState.width

export const selectIsMobile = createSelector(
  selectWidth,
  (width) => width < LargeTabletBreakpoint,
)

export const isBelowViewportWidth = createSelector(
  [selectWidth, (state, viewportWidth) => viewportWidth],
  (width, viewportWidth) => width < viewportWidth,
)

import * as amplitude from '@amplitude/analytics-browser'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

import getIsRobot from '../utils/getIsRobot'

import getAnonymousId from 'utils/getAnonymousId'

interface AmplitudeEventCommonProperties {
  platform: 'web'
  device_os: string
  device_os_version: string
  device_form_factor: 'mobile' | 'tablet' | 'desktop' | 'unknown'
  browser: string
  browser_version: string
  view_width: number
  view_height: number
  view_scale: number
}

export const getCommonProperties =
  async (): Promise<AmplitudeEventCommonProperties> => {
    const {
      browserName,
      osName,
      osVersion,
      isMobile,
      isTablet,
      isDesktop,
      fullBrowserVersion,
    } = await import('react-device-detect')

    return {
      platform: 'web',
      device_os: osName,
      device_os_version: osVersion,
      device_form_factor: isMobile
        ? 'mobile'
        : isTablet
        ? 'tablet'
        : isDesktop
        ? 'desktop'
        : 'unknown',
      browser: browserName,
      browser_version: fullBrowserVersion,
      view_width: window.innerWidth,
      view_height: window.innerHeight,
      view_scale: window.devicePixelRatio,
    }
  }

const sampleRate = Number(process.env.NEXT_PUBLIC_AMPLITUDE_SAMPLE_RATE)
const sessionReplayTracking = sessionReplayPlugin({
  sampleRate: isNaN(sampleRate) ? 0 : sampleRate,
})

amplitude.add(sessionReplayTracking)

if (typeof window !== 'undefined') {
  amplitude.init(process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '', {
    defaultTracking: {
      sessions: true,
    },
  })
}

export const identifyUser = async (
  email?: string,
  firstName?: string,
  lastName?: string,
) => {
  try {
    if (getIsRobot()) return

    const commonProperties = await getCommonProperties()
    const traits = {
      anonymousId: getAnonymousId(),
      ...commonProperties,
    }

    const identify = new amplitude.Identify()
      .set('device_os', traits.device_os)
      .set('device_os_version', traits.device_os_version)
      .set('device_form_factor', traits.device_form_factor)
      .set('browser', traits.browser)
      .set('browser_version', traits.browser_version)
      .set('view_width', traits.view_width)
      .set('view_height', traits.view_height)
      .set('view_scale', traits.view_scale)

    if (email) {
      identify.set('email', email)
    }

    if (firstName && lastName) {
      identify.set('firstName', firstName).set('lastName', lastName)
    }

    amplitude.setDeviceId(traits.anonymousId)
    amplitude.identify(identify)
  } catch (error) {
    console.error(`Error identifying user: ${error}`)
  }
}

export default amplitude

// Only US phone numbers
export const validatePhone = (num: string) => {
  const validator = /^\d{3}\?[\s.-]?\d{3}[\s.-]?\d{4}$/
  if (num) {
    if (validator.test(num)) {
      return true
    } else {
      return false
    }
  }
  return false
}

export const checkValidateEmail = (email: string) => {
  const validator =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (validator.test(email)) {
    return true
  } else if (email === '') {
    return false
  }
  return false
}

// TODO Tech debt: replace isOnServerSide usage with getIsOnServerSide
export const isOnServerSide = typeof window === 'undefined'

export const getIsOnServerSide = () => {
  return typeof window === 'undefined'
}

// Custom Categories

// Allow confgure the main caegories of the consent to be displayed in the
// Custom Consent preferences modal.
const CUSTOM_DESTINATIONS = [
  {
    id: 'custom_functional',
    name: 'Functional',
    creationName: '',
    description:
      'These help us automate and evaluate your experience, allowing the site to remember your login details and providing info about how you use our site.',
    website: '',
    category: 'Functional',
  },
  {
    id: 'custom_analytics',
    name: 'Analytics',
    creationName: '',
    description:
      'These collect info, like how many times you’ve been on the site and what you do while you’re here, so we can understand and improve your experience.',
    website: '',
    category: 'Analytics',
  },
  {
    id: 'custom_advertising',
    name: 'Advertising',
    creationName: '',
    description:
      'These are used to make sure our marketing efforts across social media and the internet are relevant to you and not repetitive.',
    website: '',
    category: 'Advertising',
  },
  {
    id: 'custom_necessary',
    name: 'Essential',
    creationName: '',
    description:
      'These enable basic features — like providing a secure login — and they can only be disabled by changing your browser preferences.',
    website: '',
    category: 'Necessary',
  },
]

export { CUSTOM_DESTINATIONS }

import type { MultipleQueriesQuery } from '@algolia/client-search'

import { datedSearchRegEx } from './AlgoliaClient.constants'

export const addParamsToRequests = <T>(
  requests: ReadonlyArray<T>,
  params: any,
): Array<T> => {
  return requests.map((request: any) => {
    return {
      ...request,
      params: {
        ...request.params,
        ...params,
      },
    }
  })
}

export const addAnalyticsParams = <T>(requests: ReadonlyArray<T>): Array<T> => {
  return addParamsToRequests(requests, {
    analytics: true,
    clickAnalytics: true,
  })
}

export const addUserToken = <T>(
  requests: ReadonlyArray<T>,
  userToken: string,
): Array<T> => {
  return addParamsToRequests(requests, { userToken })
}

export const getIsDatedSearch = (
  requests: ReadonlyArray<MultipleQueriesQuery>,
): boolean => {
  return requests.every(({ params }) =>
    params?.filters ? datedSearchRegEx.test(params.filters) : false,
  )
}

export const addDatedRuleCtx = <T>(
  requests: ReadonlyArray<T>,
  searchCtx: { isDatedSearch: boolean },
): Array<T> => {
  return addParamsToRequests(requests, {
    ruleContexts: [
      searchCtx.isDatedSearch ? 'isDatedSearch' : 'notDatedSearch',
    ],
  })
}

import React, { useContext, useState, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { Destination } from '@segment/consent-manager/types/types'

import { useSelect, useDispatch } from 'store/index'

import { setBodyStyle, setShowPreferencesModal } from 'reducers/uiState'

import { selectIsMobile } from 'selectors/uiState'

import { useClickOutside } from 'hooks/useClickOutSide'
import useConsentManager from 'hooks/useConsentManager'

import ConsentSwitch from './ConsentSwitch'
import style from './CookieConsentPreferencesModal.module.scss'

import CloseIcon from 'assets/icons/icon-close.svg'

const defaultCategories = {
  custom_functional: false,
  custom_analytics: false,
  custom_advertising: false,
  custom_necessary: false,
}

const REQUIRED = 'Required'
const ALLOW = 'Allow'
const DENY = 'Deny'

export interface CookieConsentPreferencesModalProps {
  customDestinations: Array<Destination>
  destinations: Array<Destination>
  preferences: { [key: string]: string | boolean | null | undefined }
  saveConsent: (consent?: boolean) => void
  setPreferences: (preferences: any) => void
}

const CookieConsentPreferencesModal: React.FC<
  CookieConsentPreferencesModalProps
> = (props) => {
  const router = useRouter()

  const {
    customDestinations,
    destinations,
    preferences,
    setPreferences,
    saveConsent,
  } = props
  const appDispatch = useDispatch()

  const showPreferencesModal = useSelect(
    (state) => state.uiState.showPreferencesModal,
  )

  const consentDivRef = useRef<HTMLDivElement>(null)
  const isMobile = useSelect(selectIsMobile)

  const [finalPreferences, setFinalPreferences] = useState({
    ...defaultCategories,
    ...preferences,
  })

  const { toggleConsentModal } = useConsentManager()

  const handleCloseClick = () => {
    appDispatch(setShowPreferencesModal(false))
    setTimeout(() => {
      const trackingPreferences = Cookies.get('tracking-preferences')
      if (!trackingPreferences) {
        toggleConsentModal()
      }
    }, 1000)
  }

  const handleCategoryChange = (categoryId: string, isChecked: boolean) => {
    const categoryName =
      customDestinations.find((destination) => destination.id === categoryId)
        ?.category || ''
    const newPreferences = { ...finalPreferences }
    ;[...customDestinations, ...destinations]
      .filter((destination) => destination.category === categoryName)
      .forEach((destination) => {
        newPreferences[destination.id] = isChecked
      })
    setFinalPreferences({ ...defaultCategories, ...newPreferences })
  }

  const handleSavePreferences = () => {
    setPreferences(finalPreferences)
    saveConsent()
    appDispatch(setShowPreferencesModal(false))
    router.reload()
  }

  useEffect(() => {
    setFinalPreferences({ ...defaultCategories, ...preferences })
  }, [preferences])

  useClickOutside(consentDivRef, () => handleCloseClick(), !isMobile)

  useEffect(() => {
    if (!isMobile || !showPreferencesModal) {
      appDispatch(setBodyStyle({ overflow: '' }))
      return
    }
    appDispatch(setBodyStyle({ overflow: 'hidden' }))
  }, [isMobile, showPreferencesModal, appDispatch])

  if (!showPreferencesModal) {
    return null
  }

  function getPreferenceStatus(destination) {
    if (destination.id === 'custom_necessary') return REQUIRED
    return finalPreferences[destination.id] ? ALLOW : DENY
  }

  return (
    <div className={style.scmOverlay}>
      <div className={style.scmPopup} ref={consentDivRef}>
        <h2>Customize your cookie preferences</h2>
        <button className={style.scmIcon} onClick={handleCloseClick}>
          <CloseIcon width="22" />
        </button>
        <div className={style.scmContent}>
          <p>
            This technology makes our site function, optimizes your experience,
            and helps us improve. You can change your preferences anytime in
            cookie settings.
          </p>
          <section className={style.scmCategoryListSection}>
            {customDestinations.map((destination) => (
              <div className={style.item} key={destination.id}>
                <div>
                  {destination.id !== 'custom_necessary' && (
                    <ConsentSwitch
                      isChecked={finalPreferences[destination.id]}
                      onChange={(e) => handleCategoryChange(destination.id, e)}
                    />
                  )}
                  <div> {getPreferenceStatus(destination)} </div>
                </div>
                <div>
                  <h3>{destination.name}</h3>
                  <p>{destination.description}</p>
                </div>
              </div>
            ))}
          </section>
        </div>
        <div className={style.scmButtonContainer}>
          <button
            className={'btn-noStyle'}
            onClick={handleCloseClick}
            type="button"
          >
            Cancel
          </button>
          <button
            className={'btn-primary'}
            onClick={handleSavePreferences}
            type="button"
          >
            Save Preferences
          </button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsentPreferencesModal

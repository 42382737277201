import { combineReducers } from '@reduxjs/toolkit'

import searchSlice from 'store/search'

import resultDetailSlice from './resultDetail'
import searchDataSlice from './searchData'
import uiStateSlice from './uiState'
import favoritesSlice from './favorites'

const rootReducer = combineReducers({
  resultDetail: resultDetailSlice.reducer,
  searchData: searchDataSlice.reducer,
  uiState: uiStateSlice.reducer,
  search: searchSlice.reducer,
  favorites: favoritesSlice.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

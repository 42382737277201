import snakecaseKeys from 'snakecase-keys'

import getAnonymousId from 'utils/getAnonymousId'
import getIsRobot from 'utils/getIsRobot'
import getUserId from 'utils/getUserId'

export const trackOnClient = (name: string, payload?: any) => {
  if (getIsRobot()) return

  const transformedPayload = snakecaseKeys(payload)

  window.analytics.track(name, transformedPayload)
}

export const trackOnServer = async (
  name: string,
  payload?: any,
  {
    withoutPrefix,
  }: {
    withoutPrefix?: boolean
  } = {
    withoutPrefix: false,
  },
) => {
  if (getIsRobot()) return

  const transformedPayload = snakecaseKeys(payload)
  const anonymousId = await getAnonymousId()
  const userId = await getUserId()
  const searchParams = new URLSearchParams(window.location.search)
  const navigator = window.navigator

  const userAgentData = navigator.userAgentData
    ? {
        brands: navigator.userAgentData.brands,
        mobile: navigator.userAgentData.mobile,
        platform: navigator.userAgentData.platform,
      }
    : undefined

  fetch('/api/t', {
    method: 'POST',
    body: JSON.stringify({
      name: name,
      anonymousId,
      userId,
      path: window.location.pathname,
      search: window.location.search,
      title: document.title,
      url: window.location.href,
      userAgentData,
      analyticsSessionId: localStorage.getItem('analytics_session_id'),
      withoutPrefix,
      properties: transformedPayload,
      campaign: {
        content: searchParams.get('utm_content'),
        medium: searchParams.get('utm_medium'),
        name: searchParams.get('utm_campaign'),
        source: searchParams.get('utm_source'),
        term: searchParams.get('utm_term'),
      },
    }),
  })
}

export const identify = (email: string, payload: any) => {
  if (getIsRobot()) return

  window.analytics.identify(email?.toLowerCase(), snakecaseKeys(payload))
}

export const page = ({
  name,
  referrer,
  payload,
}: {
  name: string
  referrer?: string | null
  payload?: any
}) => {
  if (getIsRobot()) return

  window.analytics.page(name, {
    referrer,
    title: name,
    ...snakecaseKeys(payload ?? {}),
  })
}

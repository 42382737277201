import React from 'react'

import style from './ConsentSwitch.module.scss'

interface IProps {
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

const ConsentSwitch: React.FC<IProps> = (props) => (
  <label className={style.switch}>
    <input
      checked={props.isChecked}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        props.onChange(e.target.checked)
      }
      type="checkbox"
    />
    <span className={style.slider}></span>
  </label>
)

export default ConsentSwitch

import { getCookieByName } from './Strings'

import { isOnServerSide } from 'utils/validations'

export default async function getUserId(): Promise<string | null | undefined> {
  if (!isOnServerSide) {
    const userId = localStorage.getItem('ajs_user_id')
    const parsedUserId = userId ? JSON.parse(userId) : null
    const existingUserId = parsedUserId ?? getCookieByName('ajs_user_id')

    if (existingUserId) {
      return existingUserId
    }
  }
}

import parse from 'qs/lib/parse'
import type { SearchState } from 'react-instantsearch-core'

import getLOSNights from 'utils/strings/getLOSNights'
import { decodeSearchQuery } from 'utils/Strings'

const urlToSearchState = (path: string): SearchState => {
  const state = path.includes('?')
    ? parse(path.substring(path.indexOf('?') + 1))
    : {}

  const newState: SearchState = { ...state }

  // custom range filters
  const range: {
    [index: string]: any
  } = {}

  if (state['minBedrooms']) {
    range['Bedrooms'] = {
      ...range['Bedrooms'],
      min: parseInt(state['minBedrooms'] as string),
    }
  }

  if (state['maxBedrooms']) {
    range['Bedrooms'] = {
      ...range['Bedrooms'],
      max: parseInt(state['maxBedrooms'] as string),
    }
  }

  if ('minBeds' in state) {
    range['Total Beds'] = {
      ...range['Total Beds'],
      min: parseInt(state['minBeds'] as string),
    }
  }

  if ('maxBeds' in state) {
    range['Total Beds'] = {
      ...range['Total Beds'],
      max: parseInt(state['maxBeds'] as string),
    }
  }

  if ('minBathrooms' in state) {
    range['Bathrooms'] = {
      ...range['Bathrooms'],
      min: parseInt(state['minBathrooms'] as string),
    }
  }

  if ('maxBathrooms' in state) {
    range['Bathrooms'] = {
      ...range['Bathrooms'],
      max: parseInt(state['maxBathrooms'] as string),
    }
  }

  if ('minAverage-Per-Night' in state) {
    range['Average Per Night'] = {
      ...range['Average Per Night'],
      min: parseInt(state['minAverage-Per-Night'] as string),
    }
  }

  if ('maxAverage-Per-Night' in state) {
    range['Average Per Night'] = {
      ...range['Average Per Night'],
      max: parseInt(state['maxAverage-Per-Night'] as string),
    }
  }

  if (state.startDate && state.endDate) {
    const nights = getLOSNights(`${state.startDate}`, `${state.endDate}`)
    if ('minLOS_PriceAverages' in state) {
      range[`LOS_PriceAverages.${state.startDate}.${nights}`] = {
        ...range[`LOS_PriceAverages.${state.startDate}.${nights}`],
        min: parseInt(state['minLOS_PriceAverages'] as string),
      }
    }

    if ('maxLOS_PriceAverages' in state) {
      range[`LOS_PriceAverages.${state.startDate}.${nights}`] = {
        ...range[`LOS_PriceAverages.${state.startDate}.${nights}`],
        max: parseInt(state['maxLOS_PriceAverages'] as string),
      }
    }
  }

  if (state.adults || state.children || state.infants || state.pets) {
    newState.guests = {
      total: (
        (state.adults ? parseInt(state.adults as string) : 0) +
        (state.children ? parseInt(state.children as string) : 0)
      ).toString(),
      adults: state.adults ? state.adults : '0',
      children: state.children ? state.children : '0',
      infants: state.infants ? state.infants : '0',
      pets: state.pets ? state.pets : '0',
    }

    range['Max Occupancy'] = {
      ...range['Max Occupancy'],
      min: parseInt(newState.guests.total),
    }
  }

  // @todo - filter by average per night is currently freezing the browser (it was like this before EVR-287 work)
  //
  // if ('Average Per Night' in state && Object.keys(state['Average Per Night']).length) {
  //   range['Average Per Night'] = {
  //     min: +state['Average Per Night']['min'],
  //     max: +state['Average Per Night']['max']
  //   };
  //   delete newState['Average Per Night'];
  // };

  if (Object.keys(range).length) {
    newState['range'] = range
  }

  // custom refinementList filters
  const refinementList: {
    [index: string]: any
  } = {}

  let amenities
  if ('amenities.Amenities' in state) {
    amenities =
      typeof state['amenities.Amenities'] === 'string'
        ? [state['amenities.Amenities']].filter(Boolean)
        : (state['amenities.Amenities'] as string[])
  } else if ('amenities.Property-Amenities' in state)
    amenities =
      typeof state['amenities.Amenities'] === 'string'
        ? [state['amenities.Amenities']].filter(Boolean)
        : (state['amenities.Amenities'] as string[])

  if (amenities)
    refinementList['amenities.Amenities'] = amenities.map((amenity) => {
      if (
        amenity.includes('Air-Conditioning') ||
        amenity.includes('Pack-N-Play')
      ) {
        return decodeURIComponent(amenity)
      } else return decodeURIComponent(amenity).replace(/-/g, ' ')
    })

  const locations =
    typeof state['amenities.Location'] === 'string'
      ? [state['amenities.Location']].filter(Boolean)
      : (state['amenities.Location'] as string[])

  if (locations) {
    refinementList['amenities.Location'] = locations.map((location) => {
      if (location.includes('Ski-In')) {
        return decodeURIComponent(location)
      } else {
        return decodeURIComponent(location).replace(/-(?!Site)/g, ' ')
      }
    })
  }

  const views =
    typeof state['amenities.View'] === 'string'
      ? [state['amenities.View']].filter(Boolean)
      : (state['amenities.View'] as string[])

  if (views) {
    refinementList['amenities.View'] = views.map((view) =>
      decodeURIComponent(view).replace(/-/g, ' '),
    )
  }

  const accessibilities =
    typeof state['amenities.Accessibility'] === 'string'
      ? [state['amenities.Accessibility']].filter(Boolean)
      : (state['amenities.Accessibility'] as string[])

  if (accessibilities)
    refinementList['amenities.Accessibility'] = accessibilities.map(
      (accessibility) =>
        decodeURIComponent(accessibility).replace(/-(?!height)/g, ' '),
    )

  // property type is an `or` type filter - there will only ever be one value
  if ('Property Type' in state && state['Property Type'] !== '') {
    refinementList['Property Type'] = !Array.isArray(state['Property Type'])
      ? [state['Property Type']]
      : state['Property Type']
  } else if ('Property-Type' in state && state['Property-Type'] !== '') {
    refinementList['Property Type'] = !Array.isArray(state['Property-Type'])
      ? [state['Property-Type']]
      : state['Property-Type']
  }

  if (Object.keys(refinementList).length) {
    newState['refinementList'] = refinementList
  }

  // Convert city/state/country to query
  let keywords: string[] = []

  if (state.query) {
    newState.query = decodeSearchQuery(state.query as string)
  } else {
    if (state.city) {
      keywords = [...keywords, state.city as string]
    }

    if (state.state) {
      keywords = [...keywords, state.state as string]
    }

    if (state.country) {
      keywords = [...keywords, state.country as string]
    }

    if (keywords.length) {
      newState.query = keywords.join(', ')
    }
  }

  if (state.adults || state.children || state.infants || state.pets) {
    newState.guests = {
      total: (
        (state.adults ? parseInt(state.adults as string) : 0) +
        (state.children ? parseInt(state.children as string) : 0)
      ).toString(),
      adults: state.adults ? state.adults : '0',
      children: state.children ? state.children : '0',
      infants: state.infants ? state.infants : '0',
      pets: state.pets ? state.pets : '0',
    }
  }

  if (state.startDate && state.endDate) {
    newState.dates = {
      start: state.startDate,
      end: state.endDate,
    }
  }

  if (Number(state.q)) {
    newState.query = state.q as string
  }

  return newState
}

export default urlToSearchState

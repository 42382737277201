import algoliasearch, {
  AlgoliaSearchOptions,
  SearchClient,
  SearchIndex,
} from 'algoliasearch'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import { getGeocode } from 'use-places-autocomplete'
import camelcaseKeys from 'camelcase-keys'
import memoize from 'lodash/memoize'
import type { Hit, SearchResponse } from '@algolia/client-search'
import type { RequestOptions } from '@algolia/transporter'

import { setLatLng, setGtmLocationData } from 'store/search'
import store from 'store/index'

import type {
  AugmentedSearchRequest,
  GeoCode,
  ValidateQuery,
} from './AlgoliaClient.types'
import {
  addAnalyticsParams,
  addDatedRuleCtx,
  addUserToken,
  getIsDatedSearch,
  addParamsToRequests,
} from './AlgoliaClient.requestMods'

import { getAjsAnonymousId } from 'utils/localStorage'

import { algoliaSearchIndexName } from 'constants/globalEnv'

export const algoliaClient = algoliasearch(
  `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
  `${process.env.NEXT_PUBLIC_ALGOLIA_API_KEY}`,
)

const getLocationGeoCode = memoize(
  async (address: string): Promise<GeoCode | null> => {
    try {
      const res = await getGeocode({ address })

      const locationGeoCode = res[0]
      return {
        lat: locationGeoCode.geometry.location.lat(),
        lng: locationGeoCode.geometry.location.lng(),
        address: locationGeoCode.address_components,
        formattedAddress: locationGeoCode.formatted_address,
        types: locationGeoCode.types,
        partialMatch: locationGeoCode.partial_match,
      }
    } catch {
      return null
    }
  },
)

const validateRequest = ({
  query = '',
  aroundLatLng = '',
}: ValidateQuery): boolean => {
  const isQueryValid = Boolean(query)
  const isQueryNumeric = Number(query)
  const isLatLngProvided = Boolean(aroundLatLng)

  return isQueryValid && !isQueryNumeric && !isLatLngProvided
}

export const buildAugmentedGeoSearchRequestParams = (
  geoCode: Pick<GeoCode, 'lat' | 'lng' | 'partialMatch'>,
  query?: string,
  aroundRadius?: 'all' | number,
): Partial<AugmentedSearchRequest> => {
  const fallbackSearchStrategy: Partial<AugmentedSearchRequest> = {
    removeWordsIfNoResults: 'allOptional',
  }

  return geoCode.partialMatch
    ? fallbackSearchStrategy
    : pickBy(
        {
          aroundRadius,
          optionalWords: query,
          aroundLatLng: `${geoCode.lat}, ${geoCode.lng}`,
        },
        undefined,
      )
}

const getGeoSearchRequest = async ({
  query = '',
  params,
}: {
  query?: string
  params?: any
}): Promise<null | any> => {
  const aroundLatLng = get(params, 'aroundLatLng', '')
  if (!validateRequest({ query, aroundLatLng })) {
    return null
  }

  const locationGeoCode = await getLocationGeoCode(query)
  if (!locationGeoCode) {
    return null
  }

  return {
    ...params,
    ...buildAugmentedGeoSearchRequestParams(locationGeoCode, query),
  }
}

export const augmentedSearchClient: SearchClient = {
  ...algoliaClient,
  search: async (originRequests) => {
    let requests = addAnalyticsParams(originRequests)

    const userToken = getAjsAnonymousId()
    if (userToken) {
      requests = addUserToken(requests, userToken)
    }

    const isDatedSearch = getIsDatedSearch(requests)
    requests = addDatedRuleCtx(requests, { isDatedSearch })

    if (
      requests.every(({ params }) =>
        validateRequest({
          query: params?.query,
          aroundLatLng: params?.aroundLatLng,
        }),
      )
    ) {
      const query = get(requests[0], 'params.query', '')
      const locationGeoCode = await getLocationGeoCode(query)
      if (!locationGeoCode) {
        return algoliaClient.search(requests)
      }

      const geoRequests = addParamsToRequests(
        requests,
        buildAugmentedGeoSearchRequestParams(locationGeoCode, query),
      )
      store.dispatch(
        setLatLng({ lat: locationGeoCode.lat, lng: locationGeoCode.lng }),
      )
      store.dispatch(
        setGtmLocationData({
          query,
          address_components: locationGeoCode.address,
          formatted_address: locationGeoCode.formattedAddress,
          types: locationGeoCode.types,
        }),
      )
      return algoliaClient.search(geoRequests)
    } else {
      return algoliaClient.search(requests)
    }
  },
}

const searchIndex = augmentedSearchClient.initIndex(algoliaSearchIndexName)

type AugmentedSearchIndex = SearchIndex & {
  search: <T>(
    query?: string,
    request?: (RequestOptions & AlgoliaSearchOptions) | undefined,
    camelize?: boolean,
  ) => Promise<SearchResponse<T>>
}

export const augmentedSearchIndex: AugmentedSearchIndex = {
  ...searchIndex,
  search: async <T>(query, request, camelize?: boolean) => {
    const geoSearchRequest = await getGeoSearchRequest({
      query,
      params: request,
    })
    const searchResults = await searchIndex.search<T>(query, {
      ...request,
      ...geoSearchRequest,
    })

    if (camelize) {
      searchResults.hits = camelcaseKeys(searchResults.hits, {
        deep: true,
      }) as Array<Hit<T>>
    }

    return searchResults
  },
}

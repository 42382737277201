import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type ResultDetailState = {
  loading: string
  notFound: boolean
  openModal: boolean
  modalView: string
  activeMenuItem: string
  reviewModalOpen: boolean
  questionModalOpen: boolean
  cancellationPolicyModalOpen: boolean
  formRefs: Record<string, unknown>
}

const resultDetailSlice = createSlice({
  name: 'resultDetail',
  initialState: {
    notFound: false,
    openModal: false,
    modalView: 'photos',
    activeMenuItem: 'overview',
    reviewModalOpen: false,
    questionModalOpen: false,
    cancellationPolicyModalOpen: false,
  } as ResultDetailState,
  reducers: {
    toggleModal: (state) => {
      state.openModal = !state.openModal
    },
    updateModalView: (state, action: PayloadAction<string>) => {
      state.modalView = action.payload
    },
    updateActiveMenuItem: (state, action) => {
      state.activeMenuItem = action.payload
    },
    toggleReviewModal: (state) => {
      state.reviewModalOpen = !state.reviewModalOpen
    },
    openQuestionModal: (state) => {
      state.questionModalOpen = true
    },
    closeQuestionModal: (state) => {
      state.questionModalOpen = false
    },
    openCancellationPolicyModal: (state) => {
      state.cancellationPolicyModalOpen = true
    },
    closeCancellationPolicyModal: (state) => {
      state.cancellationPolicyModalOpen = false
    },
  },
})

export const {
  toggleModal,
  updateModalView,
  updateActiveMenuItem,
  toggleReviewModal,
  openQuestionModal,
  closeQuestionModal,
  openCancellationPolicyModal,
  closeCancellationPolicyModal,
} = resultDetailSlice.actions

export default resultDetailSlice

import { useEffect, RefObject } from 'react'

export function useClickOutside(
  ref: RefObject<HTMLInputElement | HTMLDivElement>,
  callback: () => void,
  isClickOutDisabled: boolean,
) {
  useEffect(() => {
    if (isClickOutDisabled) return

    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, callback, isClickOutDisabled])
}

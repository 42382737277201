import React, { createContext, useEffect, useReducer } from 'react'

const initialValue = {
  advertising: false,
  analytics: false,
  functional: false,
  showConsentModal: false,
}

export enum ConsentContextActionType {
  SET_ADVERTISING_CONSENT = 'SET_ADVERTISING_CONSENT',
  SET_ANALYTICS_CONSENT = 'SET_ANALYTICS_CONSENT',
  SET_FUNCTIONAL_CONSENT = 'SET_FUNCTIONAL_CONSENT',
  SET_SHOW_CONSENT_MODAL = 'SET_SHOW_CONSENT_MODAL',
}

interface IAction {
  type: ConsentContextActionType
  payload: unknown
}

const reducer: React.Reducer<typeof initialValue, IAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case ConsentContextActionType.SET_FUNCTIONAL_CONSENT:
      return {
        ...state,
        functional: action.payload as boolean,
      }
    case ConsentContextActionType.SET_ANALYTICS_CONSENT:
      return {
        ...state,
        analytics: action.payload as boolean,
      }
    case ConsentContextActionType.SET_ADVERTISING_CONSENT:
      return {
        ...state,
        advertising: action.payload as boolean,
      }
    case ConsentContextActionType.SET_SHOW_CONSENT_MODAL:
      return {
        ...state,
        showConsentModal: action.payload as boolean,
      }
    default:
      return state
  }
}

export const ConsentContext = createContext<{
  state: typeof initialValue
  dispatch: React.Dispatch<IAction>
}>({
  state: initialValue,
  dispatch: () => null,
})

const ConsentContextProvider: React.FC<
  typeof initialValue & { children?: React.ReactNode }
> = (props) => {
  const { children, analytics, functional, advertising, showConsentModal } =
    props
  const [state, dispatch] = useReducer(reducer, {
    analytics,
    functional,
    advertising,
    showConsentModal,
  })

  useEffect(() => {
    dispatch({
      type: ConsentContextActionType.SET_ANALYTICS_CONSENT,
      payload: analytics,
    })
  }, [analytics])

  useEffect(() => {
    dispatch({
      type: ConsentContextActionType.SET_FUNCTIONAL_CONSENT,
      payload: functional,
    })
  }, [functional])

  useEffect(() => {
    dispatch({
      type: ConsentContextActionType.SET_ADVERTISING_CONSENT,
      payload: advertising,
    })
  }, [advertising])

  useEffect(() => {
    dispatch({
      type: ConsentContextActionType.SET_SHOW_CONSENT_MODAL,
      payload: showConsentModal,
    })
  }, [showConsentModal])

  return (
    <ConsentContext.Provider value={{ state, dispatch }}>
      {children}
    </ConsentContext.Provider>
  )
}

export default ConsentContextProvider

export const ACCESSIBILITY = 'amenities.Accessibility'
export const AMENITY = 'amenities.Amenities'
export const AVAILABILITY = 'Availability'
export const AVG_PER_NIGHT = 'Average Per Night'
export const BATHROOMS = 'Bathrooms'
export const BEDROOMS = 'Bedrooms'
export const BEDS = 'Total Beds'
export const MAX_OCCUPANCY = 'Max Occupancy'
export const LOCATION = 'amenities.Location'
export const MIN_STAY = 'MinStay'
export const PRICES_AVGS = 'LOS_PriceAverages'
export const PROPERTY_TYPE = 'Property Type'
export const VIEW = 'amenities.View'
export const OBJECT_ID = 'objectID'
export const AVG_RATING = 'Average Rating'

export type NumericAttributeName =
  | 'avgPerNight'
  | 'avgPrices'
  | 'bathrooms'
  | 'bedrooms'
  | 'beds'
  | 'maxOccupancy'
  | 'minStay'
  | 'avgRating'

export type FilterAttributeName =
  | NumericAttributeName
  | 'accessibility'
  | 'amenity'
  | 'availability'
  | 'location'
  | 'propertyType'
  | 'view'
  | 'objectId'

export type FilterAttributeValue =
  | typeof ACCESSIBILITY
  | typeof AMENITY
  | typeof AVAILABILITY
  | typeof AVG_PER_NIGHT
  | typeof BATHROOMS
  | typeof BEDROOMS
  | typeof BEDS
  | typeof MAX_OCCUPANCY
  | typeof LOCATION
  | typeof MIN_STAY
  | typeof PRICES_AVGS
  | typeof PROPERTY_TYPE
  | typeof VIEW
  | typeof OBJECT_ID
  | typeof AVG_RATING

export const FilterAttributesMap: Record<
  FilterAttributeName,
  FilterAttributeValue
> = {
  accessibility: ACCESSIBILITY,
  amenity: AMENITY,
  availability: AVAILABILITY,
  avgPerNight: AVG_PER_NIGHT,
  avgPrices: PRICES_AVGS,
  bathrooms: BATHROOMS,
  bedrooms: BEDROOMS,
  beds: BEDS,
  maxOccupancy: MAX_OCCUPANCY,
  location: LOCATION,
  minStay: MIN_STAY,
  propertyType: PROPERTY_TYPE,
  view: VIEW,
  objectId: OBJECT_ID,
  avgRating: AVG_RATING,
}

const FILTER_ATTRIBUTES = {
  ACCESSIBILITY,
  AMENITY,
  AVAILABILITY,
  AVG_PER_NIGHT,
  BATHROOMS,
  BEDROOMS,
  BEDS,
  MAX_OCCUPANCY,
  LOCATION,
  MIN_STAY,
  PRICES_AVGS,
  PROPERTY_TYPE,
  VIEW,
  OBJECT_ID,
  AVG_RATING,
}

export default FILTER_ATTRIBUTES

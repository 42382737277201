import React, { useState, useEffect, useCallback } from 'react'
import { useSession } from 'next-auth/react'

import { useDispatch, useSelect } from 'store/index'

import {
  fetchCollections,
  createCollection,
  addFavorite,
  setTempListings,
  setLoginComplete,
  addLoginListings,
  setSaveAsAuth,
} from 'reducers/favorites'

import { pushToDataLayer } from 'utils/Gtm'

// Handle post-login/signup collection-related events
export const LoginSuccess = (props) => {
  const { data: session, status } = useSession()
  const loading = status === 'loading'

  // Redux actions
  const appDispatch = useDispatch()

  // Component state
  const [fetchingIsDone, setFetchingIsDone] = useState(true)
  const [loginListings, setLoginListings] = useState<string[]>([])

  // Redux selectors
  const selectedListings = useSelect(
    (state) => state.favorites.selectedListings,
  )
  const currentCollectionId = useSelect(
    (state) => state.favorites.currentCollectionId,
  )
  const tempListings = useSelect((state) => state.favorites.tempListings)
  const loginComplete = useSelect((state) => state.favorites.loginComplete)

  // GTM tracking - new account created
  const handleNewAccountGtm = useCallback(() => {
    const firstFavoriteId = localStorage.getItem('firstFavoriteId') ?? ''

    pushToDataLayer('createAccountSuccess', {
      displayOption: localStorage.getItem('loginPromptModalOrigin') ?? '',
      favoriteCount: selectedListings.length
        ? selectedListings.length
        : firstFavoriteId
        ? 1
        : 0,
    })
    // Prevent event firing on following GET session calls (page refresh)
    localStorage.setItem('createAccountSuccessPushed', '1')
  }, [selectedListings.length])

  // Fetch user collections after login/signup
  useEffect(() => {
    if (!loading && session && !currentCollectionId && !loginComplete) {
      appDispatch(fetchCollections(session))

      if (
        process.browser &&
        !localStorage.getItem('createAccountSuccessPushed') &&
        session.isNewUser
      ) {
        handleNewAccountGtm()
      }
    }
  }, [
    fetchingIsDone,
    session,
    loading,
    currentCollectionId,
    loginComplete,
    appDispatch,
    handleNewAccountGtm,
  ])

  // Save ids of logged out favorites/first listing clicked, used to create/update collection
  useEffect(() => {
    const firstFavoriteId = localStorage.getItem('firstFavoriteId') ?? ''

    if (
      !currentCollectionId &&
      session &&
      (selectedListings.length || firstFavoriteId) &&
      !loginListings.length
    ) {
      setFetchingIsDone(false)
      setLoginListings(
        selectedListings.length
          ? selectedListings.map((l) => l.objectID)
          : [firstFavoriteId],
      )
    }
  }, [currentCollectionId, session, selectedListings, loginListings])

  // Create new collection from loginListings or add them to existing collection
  useEffect(() => {
    if (currentCollectionId && loginListings.length && session) {
      const firstFavoriteId = localStorage.getItem('firstFavoriteId') ?? ''

      if (currentCollectionId === 'noCollection') {
        appDispatch(createCollection({ listingIds: loginListings, session }))
      } else {
        // TODO: This fires after refereshing the page while logged in (loginListings are still being set in the hook above). It should only fire once after login.
        appDispatch(
          addLoginListings({
            favoritePostData: {
              collectionId: currentCollectionId,
              objectIDs: loginListings,
            },
            session,
          }),
        )
      }
      // GTM tracking - add favorite
      if (firstFavoriteId && loginListings.length === 1) {
        pushToDataLayer('favoriteListing', {
          listingId: firstFavoriteId,
          displayOption: localStorage.getItem('loginPromptModalOrigin') ?? '',
          loginStatus: true,
        })
      }
    }

    setFetchingIsDone(true)
  }, [currentCollectionId, loginListings, appDispatch, session])

  // Cleanup login data after updates
  useEffect(() => {
    if (
      fetchingIsDone &&
      currentCollectionId &&
      (currentCollectionId === 'noCollection' ||
        (currentCollectionId !== 'temp' &&
          currentCollectionId !== 'noCollection'))
    ) {
      setLoginListings([])

      if (process.browser) {
        localStorage.removeItem('firstFavoriteId')
        localStorage.removeItem('loginPromptModalOrigin')
      }

      appDispatch(setLoginComplete(true))
    }
  }, [fetchingIsDone, currentCollectionId, appDispatch])

  // Add listings favorited while waiting for createCollection response (need valid collection ID)
  useEffect(() => {
    if (
      currentCollectionId &&
      currentCollectionId !== 'temp' &&
      currentCollectionId !== 'noCollection' &&
      tempListings.length &&
      session
    ) {
      appDispatch(
        addFavorite({
          favoritePostData: {
            collectionId: currentCollectionId,
            objectIDs: tempListings,
          },
          session,
        }),
      )
      appDispatch(setTempListings([]))
    }
  }, [tempListings, currentCollectionId, appDispatch, session])

  // Tell useSaveFavoritesState to save state when logged in
  useEffect(() => {
    if (session) {
      appDispatch(setSaveAsAuth(true))
    }
  }, [session, appDispatch])

  return props.children
}

export default LoginSuccess

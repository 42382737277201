import React, { useContext, useRef } from 'react'
import { useRouter } from 'next/router'
import { Destination } from '@segment/consent-manager/types/types'
import { useDispatch } from 'react-redux'

import { useSelect } from 'store/index'

import { setShowPreferencesModal } from 'reducers/uiState'

import { selectIsMobile } from 'selectors/uiState'

import { ConsentContext } from 'context/ConsentContext'

import useConsentManager from 'hooks/useConsentManager'
import { useClickOutside } from 'hooks/useClickOutSide'
import { useRegionalConsent } from 'hooks/useRegionalConsent'

import style from './CookieConsentModal.module.scss'

import CloseIcon from 'assets/icons/icon-close.svg'

export interface CookieConsentModalProps {
  allDestinations: Array<Destination>
  saveConsent: (consent?: boolean) => void
  setPreferences: (preferences: any) => void
}

const CookieConsentModal: React.FC<CookieConsentModalProps> = (props) => {
  const router = useRouter()
  const appDispatch = useDispatch()

  const {
    state: { showConsentModal },
  } = useContext(ConsentContext)
  const isMobile = useSelect(selectIsMobile)
  const hideConsentModal = useSelect((state) => state.uiState.hideConsentModal)

  const { allDestinations, setPreferences, saveConsent } = props
  const { toggleConsentModal } = useConsentManager()
  const consentDivRef = useRef<HTMLDivElement>(null)

  const handleCustomPreferencesClick = () => {
    appDispatch(setShowPreferencesModal(true))
    toggleConsentModal()
  }

  useClickOutside(consentDivRef, toggleConsentModal, !isMobile)

  const { acceptAllConsentCookies, denyConsent } = useRegionalConsent({
    setPreferences,
    allDestinations,
    saveConsent,
  })

  if (!showConsentModal) {
    return null
  }

  const privacyPolicy = (
    <b>
      <a
        href="https://evolvevacationrental.com/privacy"
        rel="noreferrer"
        target="_blank"
      >
        privacy policy
      </a>
    </b>
  )

  return (
    <div
      className={`${style.scmContainer} ${
        hideConsentModal ? style.hideModal : ''
      } `}
      ref={consentDivRef}
    >
      <div className={style.scmPopup}>
        <h2>We use cookies to give you a great browsing experience</h2>
        <button className={style.scmIcon} onClick={denyConsent}>
          <CloseIcon width="22" />
        </button>
        <div className={style.scmContent}>
          As outlined in our {privacyPolicy} and cookies policy, cookies support
          site operation, experience, analytics, and advertising. Choose your
          preferences.
          <div className={style.scmButtonContainer}>
            <button
              className={'btn-secondary'}
              onClick={denyConsent}
              type="button"
            >
              Deny
            </button>
            <button
              className={'btn-primary'}
              onClick={() => {
                acceptAllConsentCookies()
                router.reload()
              }}
              type="button"
            >
              Accept All
            </button>
          </div>
          <div className={style.scmButtonContainer}>
            <button
              className={'btn-noStyle'}
              onClick={handleCustomPreferencesClick}
            >
              Customize Preferences
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CookieConsentModal

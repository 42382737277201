import { useCallback, useEffect } from 'react'
import { isInEUTimezone } from '@segment/in-eu'
import { Destination } from '@segment/consent-manager/types/types'

import { getTrackingPreferencesCookies } from 'utils/getCookies'

interface useRegionalConsentProps {
  allDestinations: Array<Destination>
  saveConsent: (consent?: boolean) => void
  setPreferences: (preferences: any) => void
}

interface useRegionalConsentReturn {
  acceptAllConsentCookies: () => void
  denyConsent: () => void
}

export function useRegionalConsent({
  allDestinations,
  setPreferences,
  saveConsent,
}: useRegionalConsentProps): useRegionalConsentReturn {
  const cookies = getTrackingPreferencesCookies()

  const europeRelatedTimezonesString =
    process.env.NEXT_PUBLIC_EU_RELATED_TIMEZONES ?? ''
  const europeRelatedTimezones = europeRelatedTimezonesString.split(',')

  const areOtherTimezonesRelated = europeRelatedTimezones.includes(
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  )

  const denyConsent = () => {
    setPreferences({
      custom_advertising: false,
      custom_analytics: false,
      custom_functional: false,
    })
    saveConsent()
  }

  useEffect(() => {
    if (cookies.necessary) return

    setPreferences({
      custom_necessary: true,
    })
    saveConsent()
  }, [setPreferences, saveConsent])

  const acceptAllConsentCookies = useCallback(() => {
    allDestinations.map((destination) => {
      setPreferences({
        [destination.id]: true,
      })
    })
    saveConsent()
  }, [allDestinations, setPreferences, saveConsent])

  useEffect(() => {
    const isEuropeRelated = isInEUTimezone() || areOtherTimezonesRelated
    if (isEuropeRelated) return
    if (cookies.necessary) return

    acceptAllConsentCookies()
  }, [cookies.necessary, acceptAllConsentCookies, areOtherTimezonesRelated])

  return { acceptAllConsentCookies, denyConsent }
}

export const pushToDataLayer = (
  event: string,
  data: Record<string, unknown>,
) => {
  // push event to GTM data layer

  process.browser &&
    window.dataLayer.push({
      event: event,
      ...data,
    })
}

// EVR-307 - Google Tag Manager automatically sends a pageview event upon app initialization
// we don't want to duplicate this first page view, so don't send it until navigation occurs
let firstPageView = true
export const pushVirtualPageView = (url: string, title?: string) => {
  if (!firstPageView) {
    // push event to GTM data layer
    process.browser &&
      window.dataLayer.push({
        event: 'VirtualPageview',
        pagePath: url,
        pageTitle: title
          ? title
          : document.title
          ? document.title
          : 'Evolve Vacation Rentals',
      })
  }
  firstPageView = false
}

import dayjs from 'dayjs'

import DatesConstants from 'constants/dates'

const CUTOFF_LIMIT = 35

const getLOSNights = (
  start: string | undefined,
  end: string | undefined,
  cutoffLongerStays: boolean = true,
): number => {
  const lengthOfStays = [2, 3, 4, 5, 6, 7, 8, 9, 10, 14, 21, 30]
  const dayjsStart = dayjs(start, DatesConstants.DEFAULT)
  const dayjsEnd = dayjs(end, DatesConstants.DEFAULT)
  let nights = dayjsEnd.diff(dayjsStart, 'days')
  if (!cutoffLongerStays && nights > CUTOFF_LIMIT) {
    return nights
  }

  if (!lengthOfStays.includes(nights)) {
    if (nights == 1) {
      nights = 2
    } else if (11 <= nights && nights <= 12) {
      nights = 10
    } else if (13 <= nights && nights <= 17) {
      nights = 14
    } else if (18 <= nights && nights <= 25) {
      nights = 21
    } else if (26 <= nights) {
      nights = 30
    }
  }
  return nights
}

export default getLOSNights

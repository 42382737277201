import Cookies from 'js-cookie'

interface Cookies {
  analytics: boolean
  functional: boolean
  necessary: boolean
  advertising: boolean
}

export function getTrackingPreferencesCookies(): Cookies {
  const cookies = JSON.parse(
    Cookies.get('tracking-preferences') ?? '{}',
  )?.destinations
  return {
    analytics: cookies?.custom_analytics,
    functional: cookies?.custom_functional,
    necessary: cookies?.custom_necessary,
    advertising: cookies?.custom_advertising,
  }
}

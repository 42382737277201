import { useMemo } from 'react'
import { useRouter } from 'next/router'
import type { NextRouter } from 'next/router'

import { applyPreservedQuery, preserveQuery } from 'utils/Strings'

// This hook augments the built in router to preserve UTM parameters when pushing

function useAugmentedRouter() {
  const router = useRouter()

  const augmentedRouter: NextRouter = useMemo(
    () => ({
      ...router,
      push: async (url, ...args) => {
        if (Object.keys(router.query).length) {
          const preservedQuery = preserveQuery(router.query)

          if (Object.keys(preservedQuery).length) {
            if (typeof url === 'string') {
              const newUrl = applyPreservedQuery(url, preservedQuery)
              return router.push(newUrl, ...args)
            } else {
              return router.push(
                {
                  ...url,
                  query: {
                    ...(url.query &&
                      typeof url.query !== 'string' &&
                      url.query),
                    ...preservedQuery,
                  },
                },
                ...args,
              )
            }
          } else {
            return router.push(url, ...args)
          }
        } else {
          return router.push(url, ...args)
        }
      },
    }),
    [router],
  )

  return augmentedRouter
}

export default useAugmentedRouter

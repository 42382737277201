import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Session } from 'next-auth'

import type { TrackedEventData } from '../types/segment'

import CollectionFetching from 'utils/CollectionFetching'
import * as segmentUtils from 'utils/segment'

import type {
  SimpleFavoriteListing,
  FavoriteListing,
  CollectionListing,
  FavoritePostData,
} from 'types/favorites'
import type { AlgoliaListing } from 'types/externalData'

// Helper functions
// Add shouldRemoveData property to algolia data
const updateAlgoliaData = (results: (AlgoliaListing | null)[]) => {
  return results
    .map((listing) => {
      if (listing) {
        listing.shouldRemoveData = false
        return listing
      }
      return {} as FavoriteListing
    })
    .filter((listing) => listing.objectID)
}

// Find selected listings that are resigned in algolia
const getAlgoliaResignedData = (
  selectedListings: SimpleFavoriteListing[],
  idList: string[],
) => {
  if (idList && idList.length) {
    return selectedListings
      .map((listing) =>
        idList.includes(listing.objectID)
          ? listing
          : ({} as SimpleFavoriteListing),
      )
      .filter((listing) => listing?.objectID)
  }
  return []
}

// Format listing data from the collections api to match algolia data
const getCollectionListingsData = (listings: CollectionListing[]) => {
  const selectedListings: SimpleFavoriteListing[] = []
  const selectedListingsData: FavoriteListing[] = []
  const resignedIdList: string[] = []
  const resignedListingData: SimpleFavoriteListing[] = []

  listings.forEach((listing) => {
    selectedListings.push({
      objectID: listing.externalId,
      Headline: listing.headline,
      ImageUrl: listing.images[0].url,
    })

    if (listing.status !== 'Active For Rent') {
      resignedIdList.push(listing.externalId)
      resignedListingData.push({
        objectID: listing.externalId,
        Headline: listing.headline,
        ImageUrl: listing.images[0].url,
      })
    } else {
      selectedListingsData.push({
        objectID: listing.externalId,
        Headline: listing.headline,
        ImageUrl: listing.images[0].url,
        ['Average Per Night']: Math.floor(+listing.availability.averagePrice),
        ['Average Rating']: listing.averageRating,
        Bathrooms: listing.bathrooms,
        Bedrooms: listing.bedrooms,
        City: listing.city,
        Country: listing.country,
        images: listing.images
          .map((img) => {
            return {
              Caption: img.caption,
              SortOrder: img.sortOrder,
              URL: img.url,
            }
          })
          .sort((a, b) => a.SortOrder - b.SortOrder),
        ['Max Occupancy']: listing.maxOccupancy,
        ['Number of Reviews']: listing.numberOfReviews,
        State: listing.state,
        ['Property Type']: listing.type,
        shouldRemoveData: false,
      })
    }
  })

  return {
    selectedListings,
    selectedListingsData,
    resignedIdList,
    resignedListingData,
  }
}

// Update favorites/collections state from formatted colections api data
const setCollectionsListingsData = (
  state: FavoritesState,
  action: PayloadAction<any>,
) => {
  if (action.payload && action.payload.collections?.length) {
    state.currentCollectionId = action.payload.collections[0]?.collectionId

    const listingsData = getCollectionListingsData(
      action.payload.collections[0].selectedListings,
    )

    if (listingsData) {
      state.resignedIdList = [...listingsData.resignedIdList]
      state.resignedListingData = [...listingsData.resignedListingData]

      // Filter selected lists because new listings can be added while waiting for response
      state.selectedListings = [
        ...state.selectedListings,
        ...listingsData.selectedListings.filter(
          (listing) =>
            !state.selectedListings
              .map((sl) => sl.objectID)
              .includes(listing.objectID),
        ),
      ]
      state.selectedListingsData = [...listingsData.selectedListingsData]
    }
  }
  // User has no collections
  if (action.payload && action.payload.message === 'no collections found') {
    state.currentCollectionId = 'noCollection'
  }
  // Force loginComplete if API fails or returns an error
  if (
    !action.payload ||
    (action.payload && action.payload.message !== 'no collections found')
  ) {
    state.loginComplete = true
  }
}

export type FavoritesState = {
  selectedListings: SimpleFavoriteListing[]
  selectedListingsData: FavoriteListing[]
  resignedIdList: string[]
  resignedListingData: SimpleFavoriteListing[]
  saveAsGuest: boolean
  firstFavoriteId: string
  showFavoriteNotification: boolean
  favoritesPageViewed: boolean
  currentCollectionId: string
  tempListings: string[]
  algoliaFetchPending: boolean
  collectionsFetchPending: boolean
  addLoginListingsPending: boolean
  loginComplete: boolean
  saveAsAuth: boolean
  selectedFavorite: TrackedEventData
}

// Thunks
export const fetchAlgoliaListingsByIdList = createAsyncThunk(
  'favorites/fetchAlgoliaListingsByIdList',
  async (listingIds: string[]) => {
    const { default: algoliasearch } = await import('algoliasearch')
    const algoliaClient = algoliasearch(
      `${process.env.NEXT_PUBLIC_ALGOLIA_APP_ID}`,
      `${process.env.NEXT_PUBLIC_ALGOLIA_API_KEY}`,
    )

    const index = algoliaClient.initIndex(
      process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX!,
    )

    const results = await index.getObjects<AlgoliaListing>(listingIds, {
      attributesToRetrieve: [
        'objectID',
        'Headline',
        'City',
        'State',
        'Country',
        'Property Type',
        'Average Per Night',
        'Average Rating',
        'Number of Reviews',
        'images',
        'Bathrooms',
        'Bedrooms',
        'Max Occupancy',
      ],
    })

    return results
  },
)

export const fetchCollections = createAsyncThunk(
  'favorites/fetchCollections',
  async (session: Session) =>
    await CollectionFetching.fetchCollections(session),
)

export const createCollection = createAsyncThunk(
  'favorites/createCollection',
  async ({ listingIds, session }: { listingIds: string[]; session: Session }) =>
    await CollectionFetching.createCollection(listingIds, session),
)

export const trackFavoriteEvent = createAsyncThunk(
  'favorites/trackFavoriteEvent',
  async (trackedEventData?: TrackedEventData) => {
    segmentUtils.trackFavoriteEvent(trackedEventData)
  },
)

export const addFavorite = createAsyncThunk(
  'favorites/addFavorite',
  async (
    {
      favoritePostData,
      session,
      trackedEventData,
    }: {
      favoritePostData: FavoritePostData
      session: Session
      trackedEventData?: TrackedEventData
    },
    thunkAPI,
  ) => {
    try {
      const response = await CollectionFetching.addFavorite(
        favoritePostData,
        session,
      )
      thunkAPI.dispatch(trackFavoriteEvent(trackedEventData))
      return response
    } catch (error) {
      console.error('Errors were found while trying to add a favorite', error)
    }
  },
)

export const deleteFavorite = createAsyncThunk(
  'favorites/deleteFavorite',
  async (
    {
      favoritePostData,
      session,
      trackedEventData,
    }: {
      favoritePostData: FavoritePostData
      session: Session
      trackedEventData?: TrackedEventData
    },
    thunkAPI,
  ) => {
    try {
      const response = await CollectionFetching.deleteFavorite(
        favoritePostData,
        session,
      )
      thunkAPI.dispatch(trackFavoriteEvent(trackedEventData))
      return response
    } catch (error) {
      console.error(
        'Errors were found while trying to delete a favorite',
        error,
      )
    }
  },
)

export const addLoginListings = createAsyncThunk(
  'favorites/addLoginListings',
  async ({
    favoritePostData,
    session,
  }: {
    favoritePostData: FavoritePostData
    session: Session
  }) => await CollectionFetching.addFavorite(favoritePostData, session),
)

const FavoritesSlice = createSlice({
  name: 'favorites',
  // If persistedFavoritesState is used, preloadedState must match this initialState
  initialState: {
    selectedListings: [],
    selectedListingsData: [],
    resignedIdList: [],
    resignedListingData: [],
    saveAsGuest: false,
    firstFavoriteId: '',
    showFavoriteNotification: false,
    favoritesPageViewed: false,
    currentCollectionId: '',
    tempListings: [],
    algoliaFetchPending: false,
    collectionsFetchPending: false,
    addLoginListingsPending: false,
    loginComplete: false,
    saveAsAuth: false,
    selectedFavorite: {
      eventName: '',
      price: 0,
      initiated_from: '',
      product_id: '',
    },
  } as FavoritesState,

  reducers: {
    addSelectedListing: (
      state,
      action: PayloadAction<SimpleFavoriteListing>,
    ) => {
      // WT-2420 Prevent firstFavoriteId hook from adding dupes with multiple tabs open
      if (
        !state.selectedListings.find(
          ({ objectID }) => objectID === action.payload.objectID,
        )
      ) {
        state.selectedListings = [...state.selectedListings, action.payload]
      }
    },
    addSelectedListingFromListingData: (
      state,
      action: PayloadAction<string>,
    ) => {
      const listingData = state.selectedListingsData.find(
        (listing) => listing.objectID === action.payload,
      )
      if (listingData) {
        state.selectedListings = [
          ...state.selectedListings,
          {
            objectID: listingData.objectID,
            Headline: listingData.Headline,
            ImageUrl: listingData.ImageUrl,
            shouldRemoveData: false,
          },
        ]
        listingData.shouldRemoveData = false
      }
    },
    removeSelectedListing: (state, action: PayloadAction<string>) => {
      state.selectedListings = [
        ...state.selectedListings.filter(
          (selected) => selected.objectID !== action.payload,
        ),
      ]
    },
    setShouldRemoveData: (state, action: PayloadAction<string>) => {
      state.selectedListingsData = [
        ...state.selectedListingsData.map((listing) => {
          if (listing?.objectID === action.payload) {
            listing.shouldRemoveData = true
          }
          return listing
        }),
      ]
    },
    clearShouldRemoveData: (state, action: PayloadAction<string>) => {
      state.selectedListingsData = [
        ...state.selectedListingsData.map((listing) => {
          if (listing?.objectID === action.payload) {
            listing.shouldRemoveData = false
          }
          return listing
        }),
      ]
    },
    removeListingData: (state) => {
      const idsToRemove = state.selectedListingsData
        .filter((selectedData) => selectedData?.shouldRemoveData)
        .map((listing) => listing?.objectID)

      if (idsToRemove && idsToRemove.length) {
        state.selectedListingsData = [
          ...state.selectedListingsData.filter(
            (selected) => !idsToRemove.includes(selected?.objectID),
          ),
        ]
      }
    },
    setShouldRemoveResigned: (state, action: PayloadAction<string>) => {
      state.resignedListingData = [
        ...state.resignedListingData.map((listing) => {
          if (listing?.objectID === action.payload) {
            listing.shouldRemoveData = true
          }
          return listing
        }),
      ]
    },
    removeResignedData: (state) => {
      const idsToRemove = state.resignedListingData
        .filter((resignedData) => resignedData?.shouldRemoveData)
        .map((listing) => listing?.objectID)

      if (idsToRemove && idsToRemove.length) {
        state.resignedListingData = [
          ...state.resignedListingData.filter(
            (selected) => !idsToRemove.includes(selected?.objectID),
          ),
        ]

        state.resignedIdList = [
          ...state.resignedIdList.filter((id) => !idsToRemove.includes(id)),
        ]
      }
    },
    addTempListing: (state, action: PayloadAction<string>) => {
      state.tempListings = [...state.tempListings, action.payload]
    },
    setTempListings: (state, action: PayloadAction<string[]>) => {
      state.tempListings = action.payload
    },
    clearFavoritesState: (state) => {
      state.selectedListings = []
      state.selectedListingsData = []
      state.resignedListingData = []
      state.resignedIdList = []
      state.tempListings = []
      state.currentCollectionId = ''
    },
    setSaveAsGuest: (state, action: PayloadAction<boolean>) => {
      state.saveAsGuest = action.payload
    },
    setFirstFavoriteId: (state, action: PayloadAction<string>) => {
      state.firstFavoriteId = action.payload
    },
    setShowFavoriteNotification: (state, action: PayloadAction<boolean>) => {
      state.showFavoriteNotification = action.payload
    },
    setFavoritesPageViewed: (state, action: PayloadAction<boolean>) => {
      state.favoritesPageViewed = action.payload
    },
    setCurrentCollectionId: (state, action: PayloadAction<string>) => {
      state.currentCollectionId = action.payload
    },
    setLoginComplete: (state, action: PayloadAction<boolean>) => {
      state.loginComplete = action.payload
    },
    setSaveAsAuth: (state, action: PayloadAction<boolean>) => {
      state.saveAsAuth = action.payload
    },
    setSelectedFavorite: (state, action: PayloadAction<any>) => {
      state.selectedFavorite = action.payload
    },
  },
  extraReducers: (builder) => {
    // Fetch Algolia Data
    builder.addCase(fetchAlgoliaListingsByIdList.fulfilled, (state, action) => {
      // GetObjectsResponse<TObject> type definition does not include message, but it is returned from the api response
      // https://www.algolia.com/doc/api-reference/api-methods/get-objects/#response
      if ((action.payload as any).message) {
        state.resignedIdList = [
          ...state.resignedIdList,
          ...(action.payload as any).message
            .replace(/ObjectID|does not exist|\s/gi, '')
            .split('.')
            .filter((id: string) => id),
        ]

        state.resignedListingData = getAlgoliaResignedData(
          state.selectedListings,
          state.resignedIdList,
        )
      }

      if (action.payload.results.length) {
        state.selectedListingsData = [
          ...state.selectedListingsData,
          ...updateAlgoliaData(action.payload.results),
        ]
      }
      state.algoliaFetchPending = false
    })
    builder.addCase(fetchAlgoliaListingsByIdList.pending, (state) => {
      state.algoliaFetchPending = true
    })
    builder.addCase(fetchAlgoliaListingsByIdList.rejected, (state) => {
      state.algoliaFetchPending = false
    })
    // Fetch Collections
    builder.addCase(fetchCollections.fulfilled, (state, action) => {
      setCollectionsListingsData(state, action)
      state.collectionsFetchPending = false
    })
    builder.addCase(fetchCollections.pending, (state) => {
      state.collectionsFetchPending = true
    })
    builder.addCase(fetchCollections.rejected, (state) => {
      state.collectionsFetchPending = false
    })
    // Create Collection
    builder.addCase(createCollection.fulfilled, (state, action) => {
      setCollectionsListingsData(state, action)
      state.collectionsFetchPending = false
    })
    builder.addCase(createCollection.pending, (state) => {
      state.collectionsFetchPending = true
    })
    builder.addCase(createCollection.rejected, (state) => {
      state.collectionsFetchPending = false
    })
    // Add Favorite
    builder.addCase(addFavorite.fulfilled, (state, action) => {
      setCollectionsListingsData(state, action)
    })
    // Add loginListings
    builder.addCase(addLoginListings.fulfilled, (state, action) => {
      setCollectionsListingsData(state, action)
      state.addLoginListingsPending = false
    })
    builder.addCase(addLoginListings.pending, (state) => {
      state.addLoginListingsPending = true
    })
    builder.addCase(addLoginListings.rejected, (state) => {
      state.addLoginListingsPending = false
    })
    // Delete Favorite
    builder.addCase(deleteFavorite.fulfilled, (state, action) => {
      if (
        action.payload.message &&
        action.payload.message === 'no collections found'
      ) {
        state.currentCollectionId = 'noCollection'
      }
    })
  },
})

export const {
  addSelectedListing,
  addSelectedListingFromListingData,
  removeSelectedListing,
  setSaveAsGuest,
  setFirstFavoriteId,
  removeListingData,
  setShouldRemoveData,
  clearShouldRemoveData,
  setShouldRemoveResigned,
  removeResignedData,
  setShowFavoriteNotification,
  setFavoritesPageViewed,
  clearFavoritesState,
  setCurrentCollectionId,
  addTempListing,
  setTempListings,
  setLoginComplete,
  setSaveAsAuth,
  setSelectedFavorite,
} = FavoritesSlice.actions

export default FavoritesSlice

export const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''
export const googleTagManagerParameters =
  process.env.NEXT_PUBLIC_GTM_PARAMS || ''

export const algoliaAppID = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || ''
export const algoliaAppKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
export const algoliaSearchIndexName =
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX || ''

export const listingAvailabilitySummaryAPI =
  process.env.NEXT_PUBLIC_SALESFORCE_LISTING_AVAILABILITY_SUMMARY_URL || ''

export const firstPartyCookies =
  process.env.NEXT_PUBLIC_FIRST_PARTY_COOKIES || '{}'

export const cookieDomains = process.env.NEXT_PUBLIC_COOKIES_DOMAINS || '[]'

export const awsPromotionUrl = process.env.NEXT_PUBLIC_AWS_PROMOTION_URL || ''
export const awsPromotionUrlClientId =
  process.env.NEXT_PUBLIC_AWS_PROMOTION_URL_CLIENT_ID || ''
export const awsPromotionUrlClientSecret =
  process.env.NEXT_PUBLIC_AWS_PROMOTION_URL_CLIENT_SECRET || ''

export const publicOrigin = process.env.NEXT_PUBLIC_ORIGIN || ''

export const coreService = process.env.NEXT_PUBLIC_CORE_SERVICE_URL || ''

export const kountClientId = process.env.NEXT_PUBLIC_KOUNT_CLIENT_ID || ''

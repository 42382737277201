import { SearchMetaState } from './search.types'
import { searchStateFromUrl, stringifyBoundingBox } from './search.utils'

import getLOSNights from 'utils/strings/getLOSNights'

const initialState: SearchMetaState = {
  isSearching: true,
  page: 0,
  query: searchStateFromUrl?.query ?? '',
  latLng: undefined,
  numHits: null,
  numFilteredHits: 0,
  numResults: 0,
  searchParameters: {
    attributesToRetrieve: ['*', '-amenities'],
    attributesToHighlight: [],
    clickAnalytics: true,
    getRankingInfo: true,
    aroundPrecision: 8000,
    aroundRadius: 48280,
    insideBoundingBox: stringifyBoundingBox(searchStateFromUrl?.boundingBox),
  },
  selectedAmenities:
    searchStateFromUrl?.refinementList?.['amenities.Amenities'] ?? [],
  selectedLocationAmenities:
    searchStateFromUrl?.refinementList?.['amenities.Location'] ?? [],
  selectedViewAmenities:
    searchStateFromUrl?.refinementList?.['amenities.View'] ?? [],
  selectedAccessibility:
    searchStateFromUrl?.refinementList?.['amenities.Accessibility'] ?? [],
  selectedPropertyTypes:
    searchStateFromUrl?.refinementList?.['Property Type'] ?? [],
  selectedActivities:
    searchStateFromUrl?.refinementList?.['amenities.Area Activities'] ?? [],
  selectedDates: searchStateFromUrl?.dates,
  selectedGuests: searchStateFromUrl?.range?.['Max Occupancy']?.min,
  selectedPets:
    searchStateFromUrl?.refinementList?.['amenities.Accessibility']?.includes(
      'Pets Allowed',
    ),
  selectedMinBeds: searchStateFromUrl?.range?.['Total Beds']?.min,
  selectedMinBathrooms: searchStateFromUrl?.range?.['Bathrooms']?.min,
  selectedMinBedrooms: searchStateFromUrl?.range?.['Bedrooms']?.min,
  selectedMinPrice:
    searchStateFromUrl?.range?.['Average Per Night']?.min ||
    searchStateFromUrl?.range?.[
      `LOS_PriceAverages.${searchStateFromUrl.dates?.start}.${getLOSNights(
        searchStateFromUrl.dates?.start,
        searchStateFromUrl.dates?.end,
      )}`
    ]?.min,
  selectedMaxPrice:
    searchStateFromUrl?.range?.['Average Per Night']?.max ||
    searchStateFromUrl?.range?.[
      `LOS_PriceAverages.${searchStateFromUrl?.dates?.start}.${getLOSNights(
        searchStateFromUrl.dates?.start,
        searchStateFromUrl.dates?.end,
      )}`
    ]?.max,

  shouldResetPriceRange: false,
  searchFiltered:
    searchStateFromUrl?.refinementList?.['amenities.Amenities'] ||
    searchStateFromUrl?.refinementList?.['amenities.Location'] ||
    searchStateFromUrl?.refinementList?.['amenities.View'] ||
    searchStateFromUrl?.refinementList?.['amenities.Accessibility'] ||
    searchStateFromUrl?.refinementList?.['Property Type'] ||
    searchStateFromUrl?.refinementList?.['amenities.Area Activities'] ||
    searchStateFromUrl?.range?.['Max Occupancy']?.min ||
    searchStateFromUrl?.range?.['Total Beds']?.min ||
    searchStateFromUrl?.range?.['Bathrooms']?.min ||
    searchStateFromUrl?.range?.['Bedrooms']?.min ||
    searchStateFromUrl?.range?.['Average Per Night']?.min ||
    searchStateFromUrl?.range?.['Average Per Night']?.max
      ? true
      : false,
  cachedFacets: undefined,
  cachedFacetsStats: undefined,
  shouldApplyFilters: false,
  ipAdressLatLng: '',
  moreFiltersRefined:
    searchStateFromUrl?.refinementList?.['amenities.Location'] ||
    searchStateFromUrl?.refinementList?.['amenities.View'] ||
    searchStateFromUrl?.refinementList?.['amenities.Accessibility']
      ? true
      : false,
  amenitiesFilterRefined: searchStateFromUrl?.refinementList?.[
    'amenities.Amenities'
  ]
    ? true
    : false,
  propertyTypeFilterRefined: searchStateFromUrl?.refinementList?.[
    'Property Type'
  ]
    ? true
    : false,
  gtmLocationData: {
    query: '',
    address_components: [],
    types: [],
    formatted_address: '',
  },
  shouldLoadGtm: false,
  gtmLoaded: false,
  searchFiltersMounted: false,
}

export default initialState

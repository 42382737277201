import React, {
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// Hooks
import useAugmentedRouter from 'hooks/useAugmentedRouter'

type PageHistoryContextType = {
  pageHistory: string[]
  setPageHistory: React.Dispatch<React.SetStateAction<string[]>>
  lastPage: React.MutableRefObject<string>
}

export const PageHistoryContext = createContext<PageHistoryContextType>(
  {} as PageHistoryContextType,
)

export const PageHistoryConsumer = PageHistoryContext.Consumer

type PageHistoryProviderProps = {
  children?: React.ReactNode
}

export const PageHistoryProvider: React.FC<PageHistoryProviderProps> = (
  props,
) => {
  const [pageHistory, setPageHistory] = useState<string[]>([])
  const lastPage = useRef<string>('')
  const router = useAugmentedRouter()
  useEffect(() => {
    const url = window.location.origin + router.asPath
    if (lastPage.current.split('?')[0] !== url.split('?')[0]) {
      setPageHistory((history) => [...history, url])
      lastPage.current = url
    }
  }, [router, router.asPath])

  const value = useMemo(
    () => ({
      pageHistory,
      setPageHistory,
      lastPage,
    }),
    [pageHistory, setPageHistory, lastPage],
  )

  return (
    <PageHistoryContext.Provider value={value}>
      {props.children}
    </PageHistoryContext.Provider>
  )
}

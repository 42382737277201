import searchSlice from './search.reducer'
import * as selectors from './search.selectors'
import * as utils from './search.utils'

export * from './search.thunks'

export const searchSelectors = {
  ...selectors,
}

export const searchUtils = {
  ...utils,
}

export const {
  clearAllFilters,
  clearAmenitiesFilter,
  clearBedsAndBath,
  clearMoreFilters,
  clearPropertyTypeFilter,
  resetSearchState,
  setCachedFacets,
  setCachedFacetsStats,
  setFilters,
  setGtmLoaded,
  setGtmLocationData,
  setIpAdressLatLng,
  setIsSearching,
  setLatLng,
  setNumHits,
  setPage,
  setQuery,
  setSearchFiltersMounted,
  setSelectedAccessibility,
  setSelectedActivities,
  setSelectedAmenities,
  setSelectedDates,
  setSelectedGuests,
  setSelectedLocationAmenities,
  setSelectedMaxPrice,
  setSelectedMinBathrooms,
  setSelectedMinBedrooms,
  setSelectedMinBeds,
  setSelectedMinPrice,
  setSelectedPets,
  setSelectedPropertyTypes,
  setSelectedViewAmenities,
  setShouldApplyFilters,
  setShouldLoadGtm,
  setShouldResetPriceRange,
  setAndApplyDatesToFilters,
  resetSelectedDates,
  updateSearchFiltersString,
} = searchSlice.actions

export default searchSlice

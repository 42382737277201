import type { ParsedUrlQuery } from 'querystring'

import dayjs from 'dayjs'

import { urlPrettySpaces } from './Strings'

import type { AlgoliaListing } from '../types/externalData'

import type { CombinedListing } from 'utils/staticData'

import availabilityCodes from 'constants/availableCodes'
import { UNITED_STATES_ABBR } from 'constants/countryCodes'
import dateFormats from 'constants/dates'

import { Listing } from 'types/Listing.type'

const filterBarRequiredQueryParams = [
  'minBedrooms',
  'minBeds',
  'minBathrooms',
  'minLOS_PriceAverages',
  'maxLOS_PriceAverages',
  'minAverage-Per-Night',
  'maxAverage-Per-Night',
  'Property-Type',
  'amenities.Amenities',
  'amenities.Location',
  'amenities.View',
  'amenities.Accessibility',
]

export const buildListingLink = (
  listing: AlgoliaListing | Listing,
  query?: ParsedUrlQuery,
) => {
  const country = listing.country ?? listing.Country
  const city = listing.city ?? listing.City
  const state = listing.state ?? listing.State
  const objectId = listing.objectId ?? listing.objectID
  const queryId = listing.queryId ?? listing.__queryID

  let listingSegment: string[] = []
  if (country && city) {
    const countrySegment = country && urlPrettySpaces(country.toLowerCase())
    const citySegment = city && urlPrettySpaces(city.toLowerCase())
    if (!!state?.trim()) {
      const stateSegment = state && urlPrettySpaces(state.toLowerCase())
      listingSegment = [countrySegment, stateSegment, citySegment, objectId]
    } else {
      listingSegment = [countrySegment, citySegment, objectId]
    }
  } else {
    listingSegment = [objectId]
  }

  const preservedParams: ParsedUrlQuery = {}

  for (const key in query) {
    if (
      [
        'adults',
        'children',
        'infants',
        'pets',
        'startDate',
        'endDate',
        'lc',
        'sessionid',
        'clickId',
        'market',
        'deviceType',
        'utm_source',
        'utm_medium',
        'utm_campaign',
        'utm_content',
        'utm_term',
      ]
        .concat(filterBarRequiredQueryParams)
        .includes(key)
    ) {
      preservedParams[key] = query[key]
    }
  }
  if (queryId) preservedParams.queryID = queryId

  return {
    pathname: `/vacation-rentals/${listingSegment.join('/')}`,
    query: {
      ...preservedParams,
    },
  }
}

export const errorCodeMap: {
  [index: number]: string
  [index: string]: string
} = {
  missingDates: 'Check-in and check-out dates are required',
  missingGuests: 'Guest count is required',
  1000: `We cannot complete your booking at this time. Please send us an inquiry or call ${process.env.NEXT_PUBLIC_EVOLVE_SUPPORT_TELEPHONE_NUMBER} for assistance.`,
  1001: 'No listing ID provided',
  1002: 'Check in date is required',
  1003: 'Check out date is required',
  1004: 'Check in date may not be before todays date',
  1005: 'Check in date must be before the check out date',
  1006: 'Reservation must have at least one adult',
  1007: 'No guest information provided',
  1008: 'First name is required',
  1009: 'Last name is required',
  1010: 'Email address is required',
  1011: 'Email address is required',
  1012: 'No payment method provided',
  1013: 'Evolve does not accept ____. Please change your payment method and try again.',
  1014: 'Credit Card is missing required information',
  1015: `There are no rates for this period. Please send an inquiry or call ${process.env.NEXT_PUBLIC_EVOLVE_SUPPORT_TELEPHONE_NUMBER} for assistance.`,
  1016: 'You have not met the minimum stay of __ nights.',
  1017: "You have exceeded this property's max occupancy. Please reduce your number of guests or choose a different property.",
  1018: 'This property only allows check ins on _____. Please adjust your check in date.',
  1019: 'This property only allows check outs on _____. Please adjust your check in date.',
  1020: 'This property is no longer available for your selected dates. Please select new dates and try again.',
  1021: 'This property is not available for your selected dates. Please select new dates and try again.',
  1022: 'This property is no longer available for reservations.',
  1023: 'This property does not allow pets. Please adjust your reservation details or choose a different property.',
}

export const convertErrorCode = (errorCode: string | number) => {
  if (errorCode in errorCodeMap) {
    return errorCodeMap[errorCode]
  }

  return ''
}

export const getErrorMessage = (err: { response?: any }) => {
  if (
    err.response?.data?.errors?.length &&
    'errorMessage' in err.response.data.errors[0]
  ) {
    return err.response.data.errors[0]['errorMessage']
  }

  return null
}

// Get # of nights in date range
export const getTotalNights = (
  startDate: string | undefined,
  endDate: string | undefined,
): number => dayjs(endDate).diff(dayjs(startDate), 'days') ?? 0

// Get average or total price for nights in date range
export const getPriceRateByDateRange = (
  startDate: string | undefined,
  endDate: string | undefined,
  pricePerDay: { [key: string]: number },
  isTotal?: boolean,
): number => {
  let price = 0

  if (startDate && endDate) {
    const nights = getTotalNights(startDate, endDate)

    // Accumulate prices for each night
    for (let i = 0; i < nights; i++) {
      const date = dayjs(startDate).add(i, 'days').format('YYYYMMDD')
      if (pricePerDay[date]) {
        price += pricePerDay[date]
      }
    }

    return isTotal ? price : price / nights
  }

  return price
}

type AvailabilityList = {
  [index: string]: string
}

type MinStayList = {
  [index: string]: number
}

// Get next available check-in and check-out dates from Algolia listing
export const getNextAvailableDateRange = (
  availability: AvailabilityList = {},
  minStays: MinStayList = {},
  nights?: number,
) => {
  const CheckInCodes = [
    availabilityCodes.AVAILABLE,
    availabilityCodes.STAY_OR_CHECK_IN,
    availabilityCodes.ONLY_CHECK_IN,
    availabilityCodes.ONLY_CHECK_IN_OR_OUT,
  ]
  const StayCodes = [
    availabilityCodes.AVAILABLE,
    availabilityCodes.STAY_OR_CHECK_IN,
    availabilityCodes.STAY_OR_CHECK_OUT,
    availabilityCodes.STAY_ONLY,
  ]
  const CheckOutCodes = [
    availabilityCodes.AVAILABLE,
    availabilityCodes.STAY_OR_CHECK_OUT,
    availabilityCodes.ONLY_CHECK_OUT,
    availabilityCodes.ONLY_CHECK_IN_OR_OUT,
  ]

  const availableCheckInDates = Object.keys(availability)
    .filter((date) => CheckInCodes.includes(availability[date]))
    .sort()
  const result: string[] = []

  for (const date of availableCheckInDates) {
    let isValid = true
    const minStay = minStays[date] || 1
    const beginDate = dayjs(date, dateFormats.DEFAULT)

    const stayLength = nights || minStay
    const now = dayjs()
    const endDate = now.add(stayLength, 'days')

    if (beginDate < now || beginDate >= endDate) {
      continue
    }

    for (let i = 0; i < stayLength; i++) {
      const checkDate = beginDate.add(i, 'days')
      const checkDateString = checkDate.format(dateFormats.DEFAULT)

      if (
        i === stayLength - 1 &&
        !CheckOutCodes.includes(availability[checkDateString])
      ) {
        isValid = false
        break
      } else if (!StayCodes.includes(availability[checkDateString])) {
        isValid = false
        break
      }
    }

    if (isValid) {
      result.push(date)
    }
  }

  return { checkIn: result[0], checkOut: result[nights || minStays[result[0]]] }
}

export const calculateTotalPriceForHit = (
  hit: Partial<AlgoliaListing>,
  startDate?: string,
  endDate?: string,
  stayLength?: number,
  hasPetsSelected?: boolean,
): number => {
  let rateTotalWithFees = 0

  if (startDate && endDate) {
    rateTotalWithFees = getPriceRateByDateRange(
      startDate,
      endDate,
      hit?.PricePerDay ?? {},
      true,
    )
  } else {
    const { checkIn, checkOut } = getNextAvailableDateRange(
      hit?.Availability,
      hit?.MinStay,
      stayLength,
    )
    rateTotalWithFees = getPriceRateByDateRange(
      checkIn,
      checkOut,
      hit?.PricePerDay ?? {},
      true,
    )
  }

  if (hasPetsSelected) {
    const petFee = hit?.fees?.pet?.amount || 0
    rateTotalWithFees += petFee
  }

  rateTotalWithFees += hit?.fees?.cleaning?.amount || 0

  const { amount: guestServiceFeeAmount, maxPrice = 750 } =
    hit?.fees?.guestService || {}

  const guestServiceFee = guestServiceFeeAmount
    ? Math.min(rateTotalWithFees * (guestServiceFeeAmount / 100), maxPrice)
    : 0

  const damageProtection =
    (hit?.fees?.damageProtection?.amount || 0) * (stayLength || 1)

  return rateTotalWithFees + guestServiceFee + damageProtection
}

type MetaTitleFormula = (listing: CombinedListing | null | undefined) => string
// DD-1445 Regex match method to update Meta Title Formula based off City name within Headline
export const metaTitleFormula: MetaTitleFormula = (listing) => {
  const headline = listing?.Headline || ''
  const country = listing?.country || listing?.Country
  const city = listing?.city || listing?.City
  const state = listing?.state || listing?.State

  if (headline.match(city)) {
    return `${headline}`
  } else if (country != 'US') {
    return `${headline} - ${city}, ${country}`
  } else {
    return `${headline} - ${city}, ${state}`
  }
}

export const getFullFormLocationFromListing = (
  listing: CombinedListing,
): {
  country: string
  state: string
  city: string
} => {
  const country =
    listing.Country === UNITED_STATES_ABBR ? 'USA' : listing.Country
  const { state } = listing.units[0]?.addresses[0]
  const { City: city } = listing

  return {
    country,
    state,
    city,
  }
}

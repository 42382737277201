import { v4 as uuidv4, validate as uuidValidate } from 'uuid'

import { getCookieByName } from './Strings'

import { isOnServerSide } from 'utils/validations'

function createAnonymousId() {
  const anonymousId = uuidv4()
  localStorage.setItem('ajs_anonymous_id', JSON.stringify(anonymousId))
  document.cookie = `ajs_anonymous_id=${anonymousId}; max-age=31536000; path=/; samesite=strict`
  return anonymousId
}

function getCookieAnonymousId() {
  const cookieAnonymousId = getCookieByName('ajs_anonymous_id')

  if (cookieAnonymousId && uuidValidate(cookieAnonymousId)) {
    // Ensure the localstorage is in sync with the cookie
    localStorage.setItem('ajs_anonymous_id', JSON.stringify(cookieAnonymousId))
    return cookieAnonymousId
  }
}

function getLocalStorageAnonymousId() {
  // Check the cookie first
  const cookieAnonymousId = getCookieAnonymousId()

  if (cookieAnonymousId) {
    return cookieAnonymousId
  }

  // No cookie was found. Check localstorage or create a new id
  try {
    const anonymousId = JSON.parse(
      localStorage.getItem('ajs_anonymous_id') ?? '',
    )

    if (!anonymousId || !uuidValidate(anonymousId)) {
      return createAnonymousId()
    }

    return anonymousId
  } catch (e) {
    return createAnonymousId()
  }
}

export default function getAnonymousId() {
  if (!isOnServerSide) {
    return getLocalStorageAnonymousId()
  }

  return ''
}

import {
  configureStore,
  AnyAction,
  Middleware,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import { useSelector, useDispatch as _useDispatch } from 'react-redux'
import {
  withReduxStateSync,
  createStateSyncMiddleware,
  initStateWithPrevTab,
  Config as SyncConfig,
} from 'redux-state-sync'
import type { TypedUseSelectorHook } from 'react-redux'

import rootReducer from '../reducers'
import { loadFavoritesState } from '../utils/localStorage'

// Types

// Load favorites state from local storage for preloadedState
const persistedFavoritesState = process.browser
  ? loadFavoritesState()
  : undefined

// Only allow selected favorites actions to persist cross-tab
const stateSyncConfig: SyncConfig = {
  predicate: (action: AnyAction) => {
    const blackList = [
      'TempListing',
      '/addFavorite/pending',
      '/createCollection/pending',
      '/fetchAlgoliaListingsByIdList',
    ]
    const whiteList = ['favorites/']

    const allowActionSync =
      action.type &&
      whiteList.some((whitekey) => action.type.includes(whitekey)) &&
      !blackList.some((blackkey) => action.type.includes(blackkey))

    return allowActionSync
  },
}

const store = configureStore({
  reducer: process.browser
    ? (withReduxStateSync(rootReducer) as typeof rootReducer)
    : rootReducer, // Need casting as withReduxStateSync isn't properly typed,
  // @ts-expect-error
  middleware: process.browser
    ? (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
          createStateSyncMiddleware(stateSyncConfig) as Middleware,
        )
    : getDefaultMiddleware(), // It is preferrable to use the chainable .concat(...) and .prepend(...) methods of the returned MiddlewareArray instead of the array spread operator, as the latter can lose valuable type information under some circumstances. Source: https://redux-toolkit.js.org/api/getDefaultMiddleware
  preloadedState: {
    // @ts-expect-error requires setting selectedFavorite
    favorites: persistedFavoritesState
      ? {
          selectedListings: JSON.parse(
            persistedFavoritesState.selectedListings,
          ),
          selectedListingsData: [],
          resignedIdList: [],
          resignedListingData: [],
          saveAsGuest: persistedFavoritesState.saveAsGuest === 'true',
          firstFavoriteId: '',
          showFavoriteNotification:
            persistedFavoritesState.showFavoriteNotification === 'true',
          favoritesPageViewed:
            persistedFavoritesState.favoritesPageViewed === 'true',
          currentCollectionId: persistedFavoritesState.currentCollectionId,
          tempListings: [],
          algoliaFetchPending: false,
          collectionsFetchPending: false,
          addLoginListingsPending: false,
          loginComplete: false,
          saveAsAuth: false,
        }
      : undefined,
  },
})

if (process.env.NODE_ENV === 'development' && (module as any).hot) {
  ;(module as any).hot.accept('../reducers', () => {
    store.replaceReducer(require('../reducers').default)
  })
}

export type AppStore = typeof store
export type RootState = ReturnType<typeof rootReducer>
export type AppState = ReturnType<AppStore['getState']>

export const useSelect: TypedUseSelectorHook<AppState> = useSelector
export type AppDispatch = AppStore['dispatch']
export const useDispatch = () => _useDispatch<AppDispatch>()

export default store

import React, { useState } from 'react'
import Script from 'next/script'
import snippet from '@segment/snippet'

import SegmentCookieConsentBuilder from '../SegmentCookieConsent/SegmentCookieConsentBuilder'

import { isOnServerSide } from 'utils/validations'
import getIsRobot from 'utils/getIsRobot'

const renderSnippet = () => {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_JS_API_KEY,
    page: false,
    load: false,
  } as any

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts)
  }

  return snippet.min(opts)
}

const Segment: React.FC = () => {
  const [segmentError, setSegmentError] = useState('')

  const isLocalFile = !isOnServerSide && window.location.protocol === 'file:'
  const isRobot = !isOnServerSide && getIsRobot()
  const userAgent = !isOnServerSide && window.navigator.userAgent

  const disableSegment = userAgent && userAgent.includes('e2e-tester')

  if (isLocalFile) {
    return null
  }

  return (
    <>
      <Script
        dangerouslySetInnerHTML={{
          __html: renderSnippet(),
        }}
        id="segment-script"
      />
      {!segmentError && !isRobot && !disableSegment && (
        <SegmentCookieConsentBuilder setSegmentError={setSegmentError} />
      )}
    </>
  )
}

export default Segment

import { isOnServerSide } from './validations'

import type { SimpleFavoriteListing } from 'types/favorites'

type ExperimentViewedLookup = {
  [key: string]: string
}

export const DISMISSED_BANNERS_KEY = 'dismissedBanners'
export const VIEWED_EXPERIMENTS_KEY = 'viewedExperiments'

export const loadFavoritesState = () => {
  try {
    if (process.browser && localStorage.getItem('favorites')?.length) {
      return JSON.parse(localStorage.getItem('favorites') ?? '{}')
    }
    return undefined
  } catch (err) {
    console.error('Error in loadFavoriteListingsState: ', err)
    return undefined
  }
}

export const saveFavoritesState = (
  selectedListings: SimpleFavoriteListing[],
  saveAsGuest: string,
  showFavoriteNotification: string,
  favoritesPageViewed: string,
  currentCollectionId: string,
) => {
  if (process.browser) {
    localStorage.setItem(
      'favorites',
      JSON.stringify({
        selectedListings: JSON.stringify(selectedListings),
        saveAsGuest: saveAsGuest,
        showFavoriteNotification: showFavoriteNotification,
        favoritesPageViewed: favoritesPageViewed,
        currentCollectionId: currentCollectionId,
      }),
    )
  }
}

export const getAjsAnonymousId = (): string | null => {
  if (isOnServerSide) return null
  try {
    const value = localStorage.getItem('ajs_anonymous_id')
    if (value?.length) {
      return JSON.parse(value)
    }
    return null
  } catch (err) {
    console.error('Error in getAjsAnonymousId: ', err)
    return null
  }
}

export const getDismissedBanners = (): {
  [key: string]: boolean
} => {
  if (isOnServerSide) return {}

  return JSON.parse(localStorage.getItem(DISMISSED_BANNERS_KEY) ?? '{}')
}

export const markBannerAsDismissed = (bannerId: string) => {
  if (isOnServerSide) return null

  const dismissedBannersStr = localStorage.getItem(DISMISSED_BANNERS_KEY)
  const dismissedBanners = dismissedBannersStr
    ? JSON.parse(dismissedBannersStr)
    : {}

  localStorage.setItem(
    DISMISSED_BANNERS_KEY,
    JSON.stringify({ ...dismissedBanners, [bannerId]: true }),
  )
}

export const getExperimentViewed = (experimentId: string) => {
  if (isOnServerSide) return null

  const viewedExperiments = JSON.parse(
    localStorage.getItem(VIEWED_EXPERIMENTS_KEY) ?? '{}',
  ) as ExperimentViewedLookup

  return viewedExperiments[experimentId]
}

export const markExperimentAsViewed = (
  experimentId: string,
  variant: string,
) => {
  if (isOnServerSide) return null

  const viewedExperiments = JSON.parse(
    localStorage.getItem('viewedExperiments') ?? '{}',
  ) as ExperimentViewedLookup

  viewedExperiments[experimentId] = variant

  localStorage.setItem(
    VIEWED_EXPERIMENTS_KEY,
    JSON.stringify(viewedExperiments),
  )
}

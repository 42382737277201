import get from 'lodash/get'

import type {
  FacetFilterItem,
  NumericFilterItem,
  NumericFilter,
  NestedNumericFilter,
  FilterItems,
  LogicFilterItem,
  NestedLogicFilter,
} from './AlgoliaClient.types'
import {
  JOIN_AND,
  JOIN_NOT,
  NUMERIC_OPERATORS_MAP,
} from './AlgoliaClient.constants'

import { FilterAttributesMap } from 'constants/filterAttributes'
import type {
  FilterAttributeName,
  NumericAttributeName,
} from 'constants/filterAttributes'

export const buildFacetFilters = (
  facetFilters: Partial<
    Record<FilterAttributeName, Array<string | FacetFilterItem>>
  >,
): Array<Array<string>> | undefined => {
  const filters = Object.keys(facetFilters)
    .filter(
      (facetKey) =>
        facetFilters[facetKey] !== undefined && facetFilters[facetKey].length,
    )
    .reduce((acc: Array<string | Array<string>>, key) => {
      const facetKey = key as FilterAttributeName
      const facetValues = facetFilters[facetKey]
      let filter: Array<string | Array<string>> = []
      if (facetValues) {
        const facetFilter = FilterAttributesMap[facetKey]
        filter = facetValues.map<string | Array<string>>((value) => {
          if (isString(value)) {
            return `${facetFilter}:${value}`
          } else {
            const { nestedPath, values } = value as FacetFilterItem
            const path = nestedPath ? `.${nestedPath}` : ''
            const filterValues = values.map(
              (value) => `${facetFilter}${path}:${value}`,
            )

            return filterValues.length > 1
              ? filterValues
              : filterValues.pop() || ''
          }
        })
      }

      return [...acc, ...filter]
    }, [])
    .filter((value) => value.length)

  // Algolia facetFilters accepts Array<string | Array<string>>
  // But the types are not accurate therefore we need to cast to string [][]
  return filters.length ? (filters as string[][]) : undefined
}

export const buildNumericFilters = (
  numericFacetFilters: Partial<
    Record<NumericAttributeName, number | NumericFilterItem>
  >,
): Array<string> => {
  const filters = Object.keys(numericFacetFilters)
    .filter((key) => numericFacetFilters[key] !== undefined)
    .reduce((acc: Array<string>, key): Array<string> => {
      const facetKey = key as NumericAttributeName
      const facetValue = numericFacetFilters[facetKey]
      const facetFilter = FilterAttributesMap[facetKey]

      if (isNumber(facetValue)) {
        return [
          ...acc,
          `${facetFilter} ${NUMERIC_OPERATORS_MAP.eq} ${facetValue}`,
        ]
      } else {
        const { nestedPath, ...operators } = facetValue as NumericFilterItem
        return [
          ...acc,
          ...Object.keys(operators!).map((operatorKey) => {
            const operator = NUMERIC_OPERATORS_MAP[operatorKey]
            const numericValue = facetValue![operatorKey]
            const filter = [facetFilter, operator, numericValue]
            if (nestedPath) {
              filter[0] = `${facetFilter}.${nestedPath}`
            }
            return filter.join(' ')
          }),
        ]
      }
    }, [])

  return filters
}

const applyNegativeFilter = (value: string | number) => {
  const negativeFilter = `${value}`.charAt(0) === '-' ? `${JOIN_NOT} ` : ''
  const filterValue = `${value}`.substring(negativeFilter ? 1 : 0)

  return [negativeFilter, filterValue]
}

const parseBooleanFilter = (
  attribute: string,
  value: string | number,
): string => {
  const [negativeFilter, filterValue] = applyNegativeFilter(value)
  return `${negativeFilter}${prepareAttributeNameValue(
    attribute,
  )}:${prepareAttributeNameValue(filterValue)}`
}

const parseNumericFilter = (
  attribute: string,
  filter: NumericFilter,
): string => {
  const { join, ...operators } = filter
  const operatorsKeys = Object.keys(operators)
  const filters = operatorsKeys.reduce((acc, operatorKey): Array<string> => {
    const operador = NUMERIC_OPERATORS_MAP[operatorKey]
    const filterValue = filter[operatorKey]

    return [
      ...acc,
      `${prepareAttributeNameValue(attribute)} ${operador} ${filterValue}`,
    ]
  }, [] as Array<string>)

  if (!filters.length) return ''

  return filters.length > 1
    ? `(${filters.join(` ${join ?? JOIN_AND} `)})`
    : `${filters.pop()}`
}

const prepareAttributeNameValue = <T>(value: T): string | T => {
  const hasSpace = `${value}`.indexOf(' ') >= 0
  return hasSpace ? `'${value}'` : value
}

const parseLogicFilter = (
  attribute: string,
  filter: LogicFilterItem,
): string => {
  const { value, join } = filter
  const filters = value
    .map((attributeValue): string => {
      return parseBooleanFilter(attribute, attributeValue)
    })
    .join(` ${join} `)

  return filters.length ? `(${filters})` : ''
}

const parseNestedPath = (
  attribute: string,
  filter: NestedNumericFilter | NestedLogicFilter,
): string => {
  const nestedPaths = Object.keys(filter)
  const nestedFilters = nestedPaths.map((path) => {
    const isLogicFilter = get(filter[path], 'join', false)
    const nestedPath = `${attribute}.${path}`
    if (isLogicFilter) {
      return parseLogicFilter(nestedPath, filter[path] as LogicFilterItem)
    }
    return parseNumericFilter(nestedPath, filter[path] as NumericFilter)
  })

  return nestedFilters.length ? nestedFilters.join(` ${JOIN_AND} `) : ''
}

const isString = (value: any): boolean => typeof value === 'string'
const isNumber = (value: any): boolean => typeof value === 'number'
const isStringOrNumber = (value: any): boolean =>
  isString(value) || isNumber(value)
const isNumericFilter = (
  filter: NumericFilter | NestedNumericFilter | NestedLogicFilter,
): boolean => {
  const filterAttributes = Object.keys(filter)
  const someStringNumberValue = filterAttributes.some((key) =>
    isStringOrNumber(filter[key]),
  )

  return someStringNumberValue
}

export const filterBuilder = (filterItems: FilterItems): string => {
  const attributes = Object.keys(filterItems)
  if (!attributes.length) {
    return ''
  }

  const filters = attributes.reduce((acc, attribute) => {
    const filterItem = filterItems[attribute as FilterAttributeName]
    if (!filterItem) {
      return acc
    }

    const attributeKey = FilterAttributesMap[attribute]
    if (isStringOrNumber(filterItem)) {
      return [...acc, parseBooleanFilter(attributeKey, filterItem as string)]
    }

    if (Array.isArray(filterItem)) {
      const booleanFiltersGroup = filterItem.map((value) =>
        parseBooleanFilter(attributeKey, value as string),
      )
      return [...acc, ...booleanFiltersGroup]
    }

    if (typeof filterItem === 'object') {
      if (isNumericFilter(filterItem)) {
        return [
          ...acc,
          parseNumericFilter(attributeKey, filterItem as NumericFilter),
        ]
      } else {
        return [
          ...acc,
          parseNestedPath(attributeKey, filterItem as NestedNumericFilter),
        ]
      }
    }

    return acc
  }, [] as Array<string>)

  const result = filters.length
    ? filters
        .filter((v) => v.length)
        .join(` ${JOIN_AND} `)
        .trim()
    : ''
  return result
}

export const JOIN_AND = 'AND'
export const JOIN_OR = 'OR'
export const JOIN_TO = 'TO'
export const JOIN_NOT = 'NOT'
export const NUMERIC_OPERATORS_MAP = {
  lt: '<',
  lte: '<=',
  eq: '=',
  diff: '!=',
  gte: '>=',
  gt: '>',
}
export const datedSearchRegEx = new RegExp(
  /(\(Availability\.\d+:\w+).+AND\sMinStay\.\d+\s[=<>]+\s\d+/,
)

import React, { useContext, useEffect } from 'react'
import Cookies from 'js-cookie'

import { ConsentContext } from 'context/ConsentContext'

import { getTrackingPreferencesCookies } from 'utils/getCookies'

import { firstPartyCookies, cookieDomains } from 'constants/globalEnv'

const {
  NECESSARY_COOKIES,
  ANALYTICS_COOKIES,
  ADVERTISING_COOKIES,
  FUNCTIONAL_COOKIES,
} = JSON.parse(firstPartyCookies)

const [cookieDomain] = JSON.parse(cookieDomains)

const CookieManager: React.FC = () => {
  const {
    state: { analytics, functional, advertising },
  } = useContext(ConsentContext)
  const cookieCleaner = (allowedCookies: Array<string>) => {
    Object.keys(Cookies.get()).forEach(function (cookie) {
      if (!allowedCookies.some((itm) => cookie.toLowerCase().includes(itm))) {
        if (cookieDomain)
          Cookies.set(cookie, '', { expires: -1, domain: cookieDomain })
        Cookies.set(cookie, '', { expires: -1 })
      }
    })
  }

  useEffect(() => {
    if (analytics && functional && advertising) {
      return
    }

    const allowedCookies = [...NECESSARY_COOKIES]
    if (analytics) {
      allowedCookies.push(...ANALYTICS_COOKIES)
    }
    if (advertising) {
      allowedCookies.push(...ADVERTISING_COOKIES)
    }
    if (functional) {
      allowedCookies.push(...FUNCTIONAL_COOKIES)
    }

    const timer = setInterval(() => {
      cookieCleaner(allowedCookies)
    }, 1000)
    return () => clearInterval(timer)
  }, [advertising, analytics, functional])

  return null
}

export default CookieManager

import React from 'react'

export default function useSaveUrlPathInSession(routerAsPath: string) {
  const storePathValues = () => {
    const storage = globalThis?.sessionStorage
    if (!storage) return
    const prevPath = storage.getItem('currentPath')
    storage.setItem('prevPath', prevPath || '')
    storage.setItem('currentPath', globalThis.location.pathname)
  }
  React.useEffect(() => {
    storePathValues()
  }, [routerAsPath])

  return null
}

import React, { useEffect } from 'react'
import '@csstools/normalize.css'
import 'styles/App.scss'
import 'instantsearch.css/themes/reset.css'
import 'react-toastify/dist/ReactToastify.min.css'
import '@fortawesome/fontawesome-svg-core/styles.css'
import debounce from 'lodash/debounce'
import { SessionProvider as AuthProvider } from 'next-auth/react'
import { Provider } from 'react-redux'
import Head from 'next/head'
import { toast } from 'react-toastify'
import type { AppProps } from 'next/app'
import smoothscroll from 'smoothscroll-polyfill'

import store from 'store/index'

import { setHeight, setWidth } from 'reducers/uiState'

// Todo: Refactor context to static props or redux
import GrowthBookProvider from 'context/GrowthBook'
import { BookingProvider } from 'context/BookingContext'
import { PageHistoryProvider } from 'context/PageHistoryContext'
import { BannerContextProvider } from 'context/BannerContext'
import { KountContextProvider } from 'context/KountContext'

import useAmplitude from 'hooks/useAmplitude'
import useAugmentedRouter from 'hooks/useAugmentedRouter'
import useSaveFavoritesState from 'hooks/useSaveFavoritesState'
import useSaveUrlPathInSession from 'hooks/useSaveUrlPathInSession'

import LoginSuccess from 'components/Guest/LoginSuccess'
import MaintenancePage from 'components/MaintenancePage/Maintenance'
import GoogleMapAPI from 'components/scripts/GoogleMapAPI'
import Segment from 'components/scripts/Segment'
import SegmentConsentManager from 'components/SegmentCookieConsent/SegmentConsentManager'
import Vwo from 'components/scripts/Vwo'

import { pushVirtualPageView } from 'utils/Gtm'
import getAnonymousId from 'utils/getAnonymousId'
import { getCookieByName } from 'utils/getCookieByName'
import { datadogInit } from 'utils/datadog'

// Ensure the anonymousId is set on the client before making api requests that
// require it for growthbook
getAnonymousId()

datadogInit()

const MyApp = ({ Component, pageProps }: AppProps) => {
  const router = useAugmentedRouter()
  useSaveFavoritesState()
  useSaveUrlPathInSession(router.asPath)
  useAmplitude()

  useEffect(() => {
    store.dispatch(setWidth(window.innerWidth))
    store.dispatch(setHeight(window.innerHeight))
    const handleResize = debounce(() => {
      store.dispatch(setWidth(window.innerWidth))
      store.dispatch(setHeight(window.innerHeight))
    }, 100)

    smoothscroll.polyfill()

    window.addEventListener('resize', handleResize)
    return () => {
      handleResize.cancel()
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  // We want to append UTM parameters from cookies to blog CTAs.
  useEffect(() => {
    const links = document.querySelectorAll(
      '[href*="try.evolve.com"], [href*="try.evolvevacationrental.com"]',
    )

    if (!links.length) return

    const utmParams = {
      utm_source: getCookieByName('utm_source') || '',
      utm_medium: getCookieByName('utm_medium') || '',
      utm_campaign: getCookieByName('utm_campaign') || '',
      utm_content: getCookieByName('utm_content') || '',
      utm_term: getCookieByName('utm_term') || '',
      gclid: getCookieByName('gclid') || '',
    }

    links.forEach((link) => {
      if (link.hasAttribute('href')) {
        const originalHref = link.getAttribute('href') || ''
        const urlSearchParams = new URLSearchParams(
          originalHref.split('?')[1] || '',
        )

        Object.entries(utmParams).forEach(([key, value]) => {
          if (!urlSearchParams.has(key) && value) {
            urlSearchParams.set(key, value)
          }
        })

        const updatedHref =
          originalHref.split('?')[0] + '?' + urlSearchParams.toString()
        link.setAttribute('href', updatedHref)
      }
    })
  }, [])

  useEffect(() => {
    const handleRouteChange = () => {
      pushVirtualPageView(router.asPath, document.title)
    }
    const handleRouteChangeStart = () => {
      toast.dismiss()
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeStart', handleRouteChangeStart)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeStart', handleRouteChangeStart)
    }
  }, [router.events, router.asPath])

  // react-toastify config
  useEffect(() => {
    if (process.browser) {
      // storePathValues()
      toast.configure({
        autoClose: 4000,
        closeButton: true,
        hideProgressBar: true,
        draggable: false,
        newestOnTop: false,
        limit: 3,
      })
    }
  }, [])

  // Clear all toasts when changing window visibility
  useEffect(() => {
    const handleVisibilityChange = () => {
      toast.dismiss()
    }
    window.addEventListener('visibilitychange', handleVisibilityChange)
    return () =>
      window.removeEventListener('visibilitychange', handleVisibilityChange)
  }, [])

  return (
    <GrowthBookProvider>
      <AuthProvider session={pageProps.session}>
        <Provider store={store}>
          <PageHistoryProvider>
            <BookingProvider>
              <LoginSuccess>
                <BannerContextProvider>
                  <KountContextProvider>
                    <Head>
                      <meta
                        content="width=device-width, height=device-height, initial-scale=1.0"
                        name="viewport"
                      />
                      <link
                        crossOrigin="anonymous"
                        href="https://google.com/"
                        rel="dns-prefetch"
                      />
                    </Head>
                    <GoogleMapAPI />
                    {process.env.NEXT_PUBLIC_SHOW_MAINTENANCE_PAGE ===
                    'true' ? (
                      <MaintenancePage />
                    ) : (
                      <Component {...pageProps} />
                    )}
                    <Segment />
                    <SegmentConsentManager.Analytics>
                      <Vwo />
                    </SegmentConsentManager.Analytics>
                  </KountContextProvider>
                </BannerContextProvider>
              </LoginSuccess>
            </BookingProvider>
          </PageHistoryProvider>
        </Provider>
      </AuthProvider>
    </GrowthBookProvider>
  )
}

export default MyApp

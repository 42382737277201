import React from 'react'
import Image from 'next/image'

import style from './Maintenance.module.scss'

import Caution from 'assets/images/Caution.png'

const MaintenancePage = () => {
  return (
    <div className={style.maintenanceBackground}>
      <div className={style.maintenanceBox}>
        <div className={style.image}>
          <Image
            alt="maintenance caution cone"
            height={100}
            src={Caution}
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
            width={150}
          />
        </div>
        <h1 className={style.captionHeader}>We'll be right back!</h1>
        <h3 className={style.captionBody}>
          We are down for planned maintenance. <br /> We'll be back in no time!
        </h3>
      </div>
    </div>
  )
}

export default MaintenancePage

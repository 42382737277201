import { signOut } from 'next-auth/react'
import type { Session } from 'next-auth'

import type { FavoritePostData } from 'types/favorites'

const fetchCollections = async (session: Session) => {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_SALESFORCE_FAVORITES_URL}${session?.profileId}`,
    {
      headers: {
        'x-api-key': `${process.env.NEXT_PUBLIC_COLLECTIONS_X_API_KEY}`,
        'AuthToken': `Bearer ${session?.accessToken}`,
      },
    },
  )
  const data = await res.json()

  if (
    data.collections ||
    (data.message && data.message === 'no collections found')
  ) {
    return data
  } else {
    console.error('fetchCollections - Response message: ', data)
    if (data[0].message === 'Session expired or invalid') {
      signOut({ redirect: false }).catch((err) => console.error(err))
    }
    return {}
  }
}

const createCollection = async (listingIds: string[], session: Session) => {
  // Dates must be null if not selected
  const collectionData = {
    collectionName: 'Favorite Homes',
    selectedDates: {
      startDate: null,
      endDate: null,
    },
    selectedGuests: {
      adults: 0,
      children: 0,
      infants: 0,
      pets: 0,
    },
    favoriteNames: listingIds,
  }

  const res = await fetch(
    `${process.env.NEXT_PUBLIC_SALESFORCE_FAVORITES_URL}${session?.profileId}/createCollection`,
    {
      method: 'POST',
      body: JSON.stringify(collectionData),
      headers: {
        'x-api-key': `${process.env.NEXT_PUBLIC_COLLECTIONS_X_API_KEY}`,
        'AuthToken': `Bearer ${session?.accessToken}`,
      },
    },
  )
  const data = await res.json()

  if (data.collections) {
    return data
  } else {
    console.error('createCollection - No collections in response: ', data)
    return {}
  }
}

const addFavorite = async (
  favoritePostData: FavoritePostData,
  session: Session,
) => {
  const postData = {
    collectionId: favoritePostData.collectionId,
    favoriteNames: favoritePostData.objectIDs,
  }

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_SALESFORCE_FAVORITES_URL}${session?.profileId}/addFavorite`,
    {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-api-key': `${process.env.NEXT_PUBLIC_COLLECTIONS_X_API_KEY}`,
        'AuthToken': `Bearer ${session?.accessToken}`,
      },
    },
  )

  if (!response.ok) throw `HTTP Request failed with status ${response.status}`

  const data = await response.json()

  if (data.collections) {
    return data
  } else {
    throw `addFavorite - No collections in response:  ${JSON.stringify(data)}`
  }
}

const deleteFavorite = async (
  favoritePostData: FavoritePostData,
  session: Session,
) => {
  const postData = {
    collectionId: favoritePostData.collectionId,
    favoriteName: favoritePostData.objectIDs[0],
  }
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_SALESFORCE_FAVORITES_URL}${session?.profileId}/deleteFavorite`,
    {
      method: 'POST',
      body: JSON.stringify(postData),
      headers: {
        'x-api-key': `${process.env.NEXT_PUBLIC_COLLECTIONS_X_API_KEY}`,
        'AuthToken': `Bearer ${session?.accessToken}`,
      },
    },
  )

  if (!response.ok) throw `HTTP Request failed with status ${response.status}`

  const data = await response.json()

  return data
  // Check res.data.errors?
}

const CollectionFetching = {
  fetchCollections,
  createCollection,
  addFavorite,
  deleteFavorite,
}

export default CollectionFetching

import React, {
  createContext,
  createRef,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { PaymentMethod } from '@stripe/stripe-js'

import useAugmentedRouter from 'hooks/useAugmentedRouter'
import type { AvailabilitySummary } from 'hooks/useListingAvailability'

import type { SelectedPaymentMethodName } from 'utils/getPaymentMethodConfig'
import urlToSearchState from 'utils/search/urlToSearchState'
import {
  filterAccessibilitiesInQueryObject,
  sanitizeQueryParams,
} from 'utils/Search'

import type { QuoteResponse } from 'types/externalData'

type IFormField = {
  value: string
  valid: boolean
}

export interface ISummaryForm {
  firstName: IFormField
  lastName: IFormField
  email: IFormField
  phone: IFormField & { dialCode: ''; isValidPhone: true }
  csrfToken: string
  customerId?: string
  externalId?: string
}

// Form data
export const defaultSummaryForm: ISummaryForm = {
  firstName: { value: '', valid: true },
  lastName: { value: '', valid: true },
  email: { value: '', valid: true },
  phone: { dialCode: '', value: '', valid: true, isValidPhone: true },
  csrfToken: 'missing',
}

export interface IStripeDetails {
  paymentMethod?: PaymentMethod
  error?: any
  valid: boolean
}

interface IPaymentDetailsForm {
  stripe: IStripeDetails
  nameOnCard: IFormField
  promo: IFormField
  address1: IFormField
  address2: IFormField
  city: IFormField
  state: IFormField & { iso: string }
  zip: IFormField
  country: IFormField & { iso: string }
  termsAndConditions: {
    text: string
    link: string
  }
}

const defaultPaymentDetailsForm: IPaymentDetailsForm = {
  stripe: {
    valid: false,
  },
  nameOnCard: { value: '', valid: true },
  promo: { value: '', valid: true },
  address1: { value: '', valid: true },
  address2: { value: '', valid: true },
  city: { value: '', valid: true },
  state: { value: '', iso: '', valid: true },
  zip: { value: '', valid: true },
  country: { value: 'United States', iso: 'US', valid: true },
  termsAndConditions: {
    text: 'Subject to terms and conditions.',
    link: 'https://help.evolvevacationrental.com/s/article/Do-you-offer-travel-insurance',
  },
}

export type BookingDates = {
  start: string | undefined
  end: string | undefined
}

export type BookingContextType = {
  bookingIsIdle: boolean
  setBookingIsIdle: React.Dispatch<React.SetStateAction<boolean>>
  loadingQuote: boolean
  setLoadingQuote: React.Dispatch<React.SetStateAction<boolean>>
  quote: QuoteResponse | null
  setQuote: React.Dispatch<React.SetStateAction<QuoteResponse | null>>
  quoteErrorMsg: string
  setQuoteErrorMsg: React.Dispatch<React.SetStateAction<string>>
  dates: BookingDates
  setDates: React.Dispatch<React.SetStateAction<BookingDates>>
  guests: {
    adults: number
    children: number
    infants: number
    pets: number
    total: number
  }
  setGuests: React.Dispatch<
    React.SetStateAction<{
      adults: number
      children: number
      infants: number
      pets: number
      total: number
    }>
  >
  viewDetailsOpen: boolean
  setViewDetailsOpen: React.Dispatch<React.SetStateAction<boolean>>
  houseRules: string[] | null
  setHouseRules: React.Dispatch<React.SetStateAction<any>>
  summaryFormValid: boolean
  setSummaryFormValid: React.Dispatch<React.SetStateAction<boolean>>
  summaryForm: React.MutableRefObject<ISummaryForm>
  paymentDetailsFormValid: boolean
  setPaymentDetailsFormValid: React.Dispatch<React.SetStateAction<boolean>>
  paymentDetailsForm: React.MutableRefObject<typeof defaultPaymentDetailsForm>
  paymentOptionalLineItems: any[]
  setPaymentOptionalLineItems: React.Dispatch<React.SetStateAction<any[]>>
  promosApplied: string[]
  setPromosApplied: React.Dispatch<React.SetStateAction<string[]>>
  shouldApplyPromo: boolean
  setShouldApplyPromo: React.Dispatch<React.SetStateAction<boolean>>
  shouldPromptExit: boolean
  setShouldPromptExit: React.Dispatch<React.SetStateAction<boolean>>
  bookingError: any
  setBookingError: React.Dispatch<React.SetStateAction<any>>
  bookingDetails: any
  setBookingDetails: React.Dispatch<React.SetStateAction<any>>
  bookingFormRefs: any
  setBookingFormRefs: React.Dispatch<React.SetStateAction<any>>
  listingAvailability: AvailabilitySummary | null
  setListingAvailability: React.Dispatch<React.SetStateAction<any>>
  loadingListing: boolean
  setLoadingListing: React.Dispatch<React.SetStateAction<boolean>>
  bookingRef: React.RefObject<HTMLDivElement>
  resetBookingForms: () => void
  updateDates: (dates: any, updateUrl: boolean) => void
  updateGuests: (guests: any, updateUrl: boolean) => void
  subscribeAgreementChecked: boolean
  setSubscribeAgreementChecked: React.Dispatch<React.SetStateAction<boolean>>
  testData: any
  setTestData: React.Dispatch<React.SetStateAction<any>>
  updateTestData: (newVariant: string, newValue: number) => void
  selectedPaymentMethod: SelectedPaymentMethodName
  setSelectedPaymentMethod: React.Dispatch<
    React.SetStateAction<SelectedPaymentMethodName>
  >
}

// Create a context with default values
export const BookingContext = createContext<BookingContextType>(
  {} as BookingContextType,
)

// This is the consumer of the values (state)
export const BookingConsumer = BookingContext.Consumer

type BookingProviderProps = {
  children?: React.ReactNode
}

// Create a provider (wrapper that handles the logic)
// This also allows communication to context and update
// state values throughout app with user info
export const BookingProvider: React.FC<BookingProviderProps> = (props) => {
  const router = useAugmentedRouter()

  // get initial state for dates and guests from url params
  const { dates: urlDates, guests: urlGuests } = useMemo(
    () => urlToSearchState(router.asPath),
    [router.asPath],
  )
  const [bookingIsIdle, setBookingIsIdle] = useState(false)
  const [loadingQuote, setLoadingQuote] = useState(false)
  const [quote, setQuote] = useState<QuoteResponse | null>(null)
  const [quoteErrorMsg, setQuoteErrorMsg] = useState('')
  const [dates, setDates] = useState(urlDates)
  const [guests, setGuests] = useState({
    adults: urlGuests?.adults ? +urlGuests.adults : 0,
    children: urlGuests?.children ? +urlGuests.children : 0,
    infants: urlGuests?.infants ? +urlGuests.infants : 0,
    pets: urlGuests?.pets ? +urlGuests.pets : 0,
    total:
      (urlGuests?.adults ? +urlGuests.adults : 0) +
      (urlGuests?.children ? +urlGuests.children : 0),
  })
  const [viewDetailsOpen, setViewDetailsOpen] = useState(false)
  const [houseRules, setHouseRules] = useState<string[] | null>(null)
  const [summaryFormValid, setSummaryFormValid] = useState(false)
  const summaryForm = useRef(defaultSummaryForm)
  const [paymentDetailsFormValid, setPaymentDetailsFormValid] = useState(false)
  const paymentDetailsForm = useRef(defaultPaymentDetailsForm)
  const [paymentOptionalLineItems, setPaymentOptionalLineItems] = useState<
    any[]
  >([])
  const [promosApplied, setPromosApplied] = useState<string[]>([])
  const [shouldApplyPromo, setShouldApplyPromo] = useState(false)
  const [shouldPromptExit, setShouldPromptExit] = useState(false)
  const [bookingError, setBookingError] = useState(null)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [bookingFormRefs, setBookingFormRefs] = useState({})
  const [listingAvailability, setListingAvailability] =
    useState<AvailabilitySummary | null>(null)
  const [loadingListing, setLoadingListing] = useState(false)
  const [subscribeAgreementChecked, setSubscribeAgreementChecked] =
    useState(false)

  const bookingRef = createRef<HTMLDivElement>()
  const [testData, setTestData] = useState({
    variant: '',
    value: '',
  })
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<SelectedPaymentMethodName>('default')

  const updateTestData = useCallback((newVariant, newValue) => {
    setTestData({
      variant: newVariant,
      value: newValue,
    })
  }, [])

  // handle date updates from user input
  // exported and to be used anywhere for handlling dates to ensure all date pickers and the url stay in sync
  // setDates is only used internally here, and shouldn't be used elsewhere
  const updateDates = useCallback(
    (newDates, updateUrl) => {
      // get current query params from url (inclucing dates)
      const searchState = urlToSearchState(router.asPath)

      // format those query params
      const searchParams = sanitizeQueryParams(searchState)

      // spread new dates into formatted query params object
      if (!newDates.start) {
        delete searchParams.startDate // must be deleted to remove from url
      }
      if (!newDates.end) {
        delete searchParams.endDate // must be deleted to remove from url
      }

      const paramsWithUpdatedDates = {
        ...searchParams,
        ...(newDates.start && { startDate: newDates.start }), // only spread into object if dates are defined
        ...(newDates.end && { endDate: newDates.end }), // only spread into object if dates are defined
      }

      // shallow update url with updated query param object (with updated dates)
      // updateUrl is optional because the homepage does not need to update the url
      // the url changes and needs to account for both "slug" and "listingid"
      if (updateUrl) {
        router.push(
          {
            pathname: router.pathname,
            query: {
              ...(router.query.slug
                ? { slug: router.query.slug }
                : { listingId: router.query.listingId }),
              ...paramsWithUpdatedDates,
            },
          },
          undefined,
          { shallow: true, scroll: false },
        )

        // ...and set dates in context to new user selected dates
        setDates({
          start: newDates?.start || null,
          end: newDates?.end || null,
        })
      }
    },
    [router],
  )

  const updateGuests = useCallback(
    (newGuests, updateUrl) => {
      // get current query params from url
      const searchState = urlToSearchState(router.asPath)
      const searchParams = sanitizeQueryParams(searchState)

      // spread new guests into formatted query params object
      for (const key in newGuests) {
        if (newGuests[key] === 0) {
          delete searchParams[key] // must be deleted to remove from url
        }
      }

      const paramsWithUpdatedDates = {
        ...searchParams,
        ...(newGuests.adults > 0 && { adults: newGuests.adults }),
        ...(newGuests.children > 0 && { children: newGuests.children }),
        ...(newGuests.infants > 0 && { infants: newGuests.infants }),
        ...(newGuests.pets > 0 && { pets: newGuests.pets }),
      }

      // shallow update url with updated query param object (with updated guests)
      // the url changes and needs to account for both "slug" and "listingid"
      if (updateUrl) {
        const preparedQueryObject = filterAccessibilitiesInQueryObject({
          ...(router.query.slug
            ? { slug: router.query.slug }
            : { listingId: router.query.listingId }),
          ...paramsWithUpdatedDates,
        })
        router.push(
          {
            pathname: router.pathname,
            query: preparedQueryObject,
          },
          undefined,
          { shallow: true },
        )

        // ...and set guests in context to new user selected dates
        setGuests({
          adults: newGuests.adults,
          children: newGuests.children,
          infants: newGuests.infants,
          pets: newGuests.pets,
          total: newGuests.total,
        })
      }
    },
    [router],
  )

  const resetBookingForms = useCallback(() => {
    setQuote(null)
    updateDates({ start: '', end: '' }, false)
    updateGuests(
      {
        adults: 0,
        children: 0,
        infants: 0,
        pets: 0,
        total: 0,
      },
      false,
    )
    setHouseRules(null)
    setSummaryFormValid(false)
    summaryForm.current = defaultSummaryForm
    setPaymentDetailsFormValid(false)
    paymentDetailsForm.current = defaultPaymentDetailsForm
    setSubscribeAgreementChecked(false)
  }, [updateDates, updateGuests])

  const value = useMemo(
    () => ({
      bookingIsIdle,
      setBookingIsIdle,
      loadingQuote,
      setLoadingQuote,
      quote,
      setQuote,
      quoteErrorMsg,
      setQuoteErrorMsg,
      dates,
      setDates,
      guests,
      setGuests,
      viewDetailsOpen,
      setViewDetailsOpen,
      houseRules,
      setHouseRules,
      summaryForm,
      summaryFormValid,
      setSummaryFormValid,
      paymentDetailsForm,
      paymentOptionalLineItems,
      setPaymentOptionalLineItems,
      paymentDetailsFormValid,
      setPaymentDetailsFormValid,
      promosApplied,
      setPromosApplied,
      shouldApplyPromo,
      setShouldApplyPromo,
      shouldPromptExit,
      setShouldPromptExit,
      bookingError,
      setBookingError,
      bookingDetails,
      setBookingDetails,
      bookingFormRefs,
      setBookingFormRefs,
      listingAvailability,
      setListingAvailability,
      loadingListing,
      setLoadingListing,
      bookingRef,
      resetBookingForms,
      updateDates,
      updateGuests,
      subscribeAgreementChecked,
      setSubscribeAgreementChecked,
      testData,
      setTestData,
      updateTestData,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
    }),
    [
      bookingIsIdle,
      setBookingIsIdle,
      loadingQuote,
      setLoadingQuote,
      quote,
      setQuote,
      quoteErrorMsg,
      setQuoteErrorMsg,
      dates,
      setDates,
      guests,
      setGuests,
      viewDetailsOpen,
      setViewDetailsOpen,
      houseRules,
      setHouseRules,
      summaryForm,
      summaryFormValid,
      setSummaryFormValid,
      paymentDetailsForm,
      paymentOptionalLineItems,
      setPaymentOptionalLineItems,
      paymentDetailsFormValid,
      setPaymentDetailsFormValid,
      promosApplied,
      setPromosApplied,
      shouldApplyPromo,
      setShouldApplyPromo,
      shouldPromptExit,
      setShouldPromptExit,
      bookingError,
      setBookingError,
      bookingDetails,
      setBookingDetails,
      bookingFormRefs,
      setBookingFormRefs,
      listingAvailability,
      setListingAvailability,
      loadingListing,
      setLoadingListing,
      bookingRef,
      resetBookingForms,
      updateDates,
      updateGuests,
      subscribeAgreementChecked,
      setSubscribeAgreementChecked,
      testData,
      setTestData,
      updateTestData,
      selectedPaymentMethod,
      setSelectedPaymentMethod,
    ],
  )

  return (
    <BookingContext.Provider value={value}>
      {props.children}
    </BookingContext.Provider>
  )
}

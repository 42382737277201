import { datadogRum } from '@datadog/browser-rum'

function getSamplingRateByEnv(env: string) {
  switch (env) {
    // In pre-production environments, do not capture sessions.
    case 'dev':
    case 'stage':
      return {
        sessionSampleRate: 0,
        sessionReplaySampleRate: 0,
      }
    // In production, only capture 1% of sessions, and no replay sampling.
    default:
      return {
        sessionSampleRate: 1,
        sessionReplaySampleRate: 0,
      }
  }
}

export function datadogInit() {
  if (
    process.env.NEXT_PUBLIC_DATADOG_APP &&
    process.env.NEXT_PUBLIC_DATADOG_CLIENT &&
    process.env.NEXT_PUBLIC_DATADOG_ENV
  ) {
    datadogRum.init({
      applicationId: process.env.NEXT_PUBLIC_DATADOG_APP,
      clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT,
      site: 'datadoghq.com',
      service: 'guest-web',
      env: process.env.NEXT_PUBLIC_DATADOG_ENV,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      ...getSamplingRateByEnv(process.env.NEXT_PUBLIC_DATADOG_ENV),
    })
  }
}

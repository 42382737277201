import { ParsedUrlQuery } from 'querystring'

import stringify from 'qs/lib/stringify'
import parse from 'qs/lib/parse'

export type PreservedQuery = Record<string, any>

export const stripLeadingQuerySeparator = (query: string) => {
  if (query.charAt(0) === '?') {
    query = query.substr(1)
  }

  return query
}

export const stripTrailingQuerySeparator = (query: string) => {
  if (query.charAt(query.length - 1) === '&') {
    query = query.slice(0, -1)
  }

  return query
}

export const setPrice = (
  price: number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
) => {
  if (price) {
    const newPrice = price.toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits,
      maximumFractionDigits,
    })
    return `${newPrice}`
  } else {
    return '0'
  }
}

export const setPriceWithDecimals = (input: string | number) => {
  const price = typeof input === 'string' ? input : input.toString()

  if (price && price !== '' && price !== '-') {
    const newPrice = Math.abs(parseFloat(price)).toLocaleString(undefined, {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'narrowSymbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    return parseFloat(price) < 0 ? `-${newPrice}` : `${newPrice}`
  } else {
    return '0'
  }
}

export const convertStringCurrencyToNumber = (input: string | number) => {
  const string = typeof input === 'string' ? input : input.toString()
  const value = Number(string.replace(/[^-\d\.]/g, ''))
  return !isNaN(value) ? value : 0
}

export const calcRating = (rating: string | number) => {
  if (!rating || rating === '') {
    return 0
  }
  if (typeof rating === 'string') {
    return parseFloat(rating)
  } else {
    return rating
  }
}

export const parseDataString = (str: string) => {
  if (str) {
    return str.split(/\r?\n/)
  }
}

export const toCurrency = (value: number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  })

  return formatter.format(value)
}

export const urlPrettySpaces = (string: string) => {
  return string !== ' '
    ? string.split(' ').map(encodeURIComponent).join('-')
    : ''
}

export const normalizeSearchQuery = (query: string) => {
  return query.replace(/-/g, '~').replace(/,/g, '-').replace(/\s/g, '-')
}

export const decodeSearchQuery = (query: string) => {
  return decodeURIComponent(query)
    .replace(/--/g, ', ')
    .replace(/-/g, ' ')
    .replace(/~/g, '-')
}

export const formatPhone = (dialCode: string, phoneNumber: string) => {
  return `+${dialCode} ${phoneNumber}`
}

export const stripWrappingParagraph = (str: string) => {
  if (str) {
    return str.replace(/(?:^<p[^>]*>)|(?:<\/p>$)/g, '')
  }

  return str
}

export const getCookieByName = (name: string): string | null => {
  const cookieArr = document.cookie.split(';')
  for (let i = 0; i < cookieArr.length; i++) {
    const cookiePair = cookieArr[i].split('=')
    if (name === cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1])
    }
  }
  return null
}

// Add . to reviewer's abbreviated last name (Tom B.)
export const formatReviewedBy = (reviewedBy: string) => {
  if (reviewedBy.length) {
    const splitreviewedBy = reviewedBy.split(' ')
    if (
      splitreviewedBy[splitreviewedBy.length - 1] &&
      splitreviewedBy[splitreviewedBy.length - 1].match(/^\w$/gi)
    ) {
      return `${reviewedBy}.`
    }
  }

  return reviewedBy
}

// Preserve defined query parameters as an object
export const preserveQuery = (routerQuery: ParsedUrlQuery): PreservedQuery => {
  const preservedKeys = ['lc', 'sessionid']
  const preservedQuery = {}

  for (const key in routerQuery) {
    const keysRegExp = new RegExp(preservedKeys.join('|'), 'gi')
    if (keysRegExp.test(key)) {
      preservedQuery[key] = routerQuery[key]
    }
  }

  return preservedQuery
}

// Apply query object to an existing url as a query string
export const applyPreservedQuery = (
  url: string,
  preservedQuery: PreservedQuery,
) => {
  let newUrl = ''

  // Prevent adding duplicate params
  const urlParams = new URLSearchParams(url.split('?')[1])

  if (urlParams) {
    for (const key of urlParams.keys()) {
      if (key && preservedQuery[key]) {
        delete preservedQuery[key]
      }
    }
  }

  newUrl = Object.keys(preservedQuery).length
    ? url + (url.includes('?') ? '&' : '?') + stringify(preservedQuery)
    : url

  return newUrl
}

export const preserveAndApplyQuery = (
  url: string,
  routerQuery: ParsedUrlQuery,
) => applyPreservedQuery(url, preserveQuery(routerQuery))

export const getSha256 = async (string: string) => {
  try {
    const { default: sha256 } = await import('crypto-js/sha256')
    const hash = sha256(string).toString()
    return hash
  } catch (err) {
    console.error(err)
    return ''
  }
}

export const DISALLOWED_BLOG_LINK_PARAMS = [
  '__hstc',
  '__hssc',
  '__hsfp',
  '_hsmi',
  '_hsenc',
  'hsa_',
  'hs_',
  '_ga',
  '_gid',
  '_gat',
  '_gaexp',
  'gclid',
  'utm_',
  'queryID',
  'lc',
  'sessionid',
].map((param) => param.toLowerCase())

export const cleanUpBlogLink = (link: string) => {
  const isHash = link.startsWith('#')
  if (isHash) return link

  const isRelative = link.startsWith('/')
  const url = new URL(link, window.location.origin)
  const querystring = parse(url.search, { ignoreQueryPrefix: true })

  for (const key in querystring) {
    if (
      DISALLOWED_BLOG_LINK_PARAMS.some((param) =>
        key.toLowerCase().startsWith(param),
      )
    ) {
      delete querystring[key]
    }
  }

  url.search = stringify(querystring, { addQueryPrefix: true })

  if (isRelative) {
    return url.pathname + url.search
  } else {
    return url.href
  }
}

export const mergeLinkAndQuery = (link: string, query: string) => {
  const isHash = link.startsWith('#')
  if (isHash) return link

  const isRelative = link.startsWith('/')

  const url = new URL(link, window.location.origin)
  const querystring = parse(url.search, { ignoreQueryPrefix: true })

  url.search = stringify(
    { ...querystring, ...parse(query, { ignoreQueryPrefix: true }) },
    { addQueryPrefix: true },
  )

  if (isRelative) {
    return url.pathname + url.search
  } else {
    return url.href
  }
}

export const utmParamsRegex =
  /(utm_source|utm_medium|utm_campaign|utm_content|utm_term|gclid)=([^&]*)/gi

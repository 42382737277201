import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { SearchOptions, SearchResponse } from '@algolia/client-search'
import type { SearchState } from 'react-instantsearch-core'

import {
  buildPriceFilters,
  stringifyBoundingBox,
  getAccessibilitesByPetsAllowed,
  getSearchFilters,
} from './search.utils'
import initialState from './search.initialState'
import { backgroundSearch } from './search.thunks'
import { UpdateSearchFilterString } from './search.types'
import { NO_PETS_ALLOWED, PETS_ALLOWED } from './search.constants'

import type { GeocoderQueryAndResult } from 'types/externalData'

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsSearching: (state, action: PayloadAction<boolean>) => {
      state.isSearching = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload
    },
    setQuery: (state, action: PayloadAction<string>) => {
      state.query = action.payload
    },
    setLatLng: (state, action: PayloadAction<google.maps.LatLngLiteral>) => {
      state.latLng = action.payload
    },
    setFilters: (state, action: PayloadAction<SearchOptions['filters']>) => {
      state.searchParameters.filters = action.payload
    },
    setShouldApplyFilters: (state, action: PayloadAction<boolean>) => {
      state.shouldApplyFilters = action.payload
    },
    setNumHits: (state, action: PayloadAction<number>) => {
      state.numHits = action.payload
      state.numResults = action.payload
    },
    setSelectedDates: (
      state,
      action: PayloadAction<{ start: string; end: string }>,
    ) => {
      searchSlice.caseReducers.updateSearchFiltersString(state, {
        payload: action.payload,
        type: 'updateSearchFiltersString',
      })
      state.selectedDates = action.payload
      state.searchParameters.numericFilters = undefined
      state.searchFiltered = true
    },
    setSelectedGuests: (state, action: PayloadAction<number>) => {
      state.selectedGuests = action.payload
    },
    setAndApplyDatesToFilters: (
      state,
      action: PayloadAction<{ start: string; end: string }>,
    ) => {
      state.selectedDates = action.payload
      state.searchParameters.filters = getSearchFilters(
        action.payload.start,
        action.payload.end,
      )
    },
    setSelectedPets: (
      state,
      action: PayloadAction<{
        isPetAllowed: number
        ignoreFiltering?: boolean
      }>,
    ) => {
      const { isPetAllowed, ignoreFiltering = false } = action.payload
      const stringPetsValue =
        isPetAllowed === 1 ? PETS_ALLOWED : NO_PETS_ALLOWED

      const amenities =
        ignoreFiltering && isPetAllowed == 1
          ? [...state.selectedAccessibility, PETS_ALLOWED]
          : getAccessibilitesByPetsAllowed({
              accessibilities: [...state.selectedAccessibility],
              isPetAllowed: stringPetsValue,
            })

      searchSlice.caseReducers.setSelectedAccessibility(state, {
        payload: amenities,
        type: 'setSelectedAccessibility',
      })
    },
    setSelectedAmenities: (state, action: PayloadAction<string[]>) => {
      state.selectedAmenities = action.payload
      if (action.payload.length) {
        state.amenitiesFilterRefined = true
        state.searchFiltered = true
      } else {
        state.amenitiesFilterRefined = false
      }
    },
    setSelectedLocationAmenities: (state, action: PayloadAction<string[]>) => {
      state.selectedLocationAmenities = action.payload
      if (action.payload.length) {
        state.moreFiltersRefined = true
        state.searchFiltered = true
      } else if (
        !state.selectedViewAmenities.length &&
        !state.selectedAccessibility.length
      ) {
        state.moreFiltersRefined = false
      }
    },
    setSelectedViewAmenities: (state, action: PayloadAction<string[]>) => {
      state.selectedViewAmenities = action.payload
      if (action.payload.length) {
        state.moreFiltersRefined = true
        state.searchFiltered = true
      } else if (
        !state.selectedLocationAmenities.length &&
        !state.selectedAccessibility.length
      ) {
        state.moreFiltersRefined = false
      }
    },
    setSelectedAccessibility: (state, action: PayloadAction<string[]>) => {
      state.selectedAccessibility = action.payload
      if (action.payload.length) {
        state.moreFiltersRefined = true
        state.searchFiltered = true
      } else if (
        !state.selectedViewAmenities.length &&
        !state.selectedLocationAmenities.length
      ) {
        state.moreFiltersRefined = false
      }
    },
    setSelectedPropertyTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedPropertyTypes = action.payload
      if (action.payload.length) {
        state.propertyTypeFilterRefined = true
        state.searchFiltered = true
      } else {
        state.propertyTypeFilterRefined = false
      }
    },
    setSelectedActivities: (state, action: PayloadAction<string[]>) => {
      state.selectedActivities = action.payload
      state.searchFiltered = true
    },
    setSelectedMinBeds: (state, action: PayloadAction<number>) => {
      state.selectedMinBeds = action.payload
      state.searchFiltered = true
    },
    setSelectedMinBathrooms: (state, action: PayloadAction<number>) => {
      state.selectedMinBathrooms = action.payload
      state.searchFiltered = true
    },
    setSelectedMinBedrooms: (state, action: PayloadAction<number>) => {
      state.selectedMinBedrooms = action.payload
      state.searchFiltered = true
    },
    setSelectedMinPrice: (state, action: PayloadAction<number | undefined>) => {
      state.selectedMinPrice = action.payload
      state.searchFiltered = true
    },
    setSelectedMaxPrice: (state, action: PayloadAction<number | undefined>) => {
      state.selectedMaxPrice = action.payload
      state.searchFiltered = true
    },
    setShouldResetPriceRange: (state, action: PayloadAction<boolean>) => {
      state.shouldResetPriceRange = action.payload
    },
    setCachedFacets: (
      state,
      action: PayloadAction<SearchResponse['facets']>,
    ) => {
      state.cachedFacets = action.payload
    },
    setCachedFacetsStats: (
      state,
      action: PayloadAction<SearchResponse['facets_stats']>,
    ) => {
      state.cachedFacetsStats = action.payload
    },
    setGtmLocationData: (
      state,
      action: PayloadAction<GeocoderQueryAndResult>,
    ) => {
      state.gtmLocationData = action.payload
    },
    clearMoreFilters: (state) => {
      state.selectedLocationAmenities = []
      state.selectedViewAmenities = []
      state.selectedAccessibility = []
      state.moreFiltersRefined = false
    },
    clearBedsAndBath: (state) => {
      state.selectedMinBeds = 0
      state.selectedMinBedrooms = 0
      state.selectedMinBathrooms = 0
    },
    clearAmenitiesFilter: (state) => {
      state.selectedAmenities = []
      state.amenitiesFilterRefined = false
    },
    clearPropertyTypeFilter: (state) => {
      state.selectedPropertyTypes = []
      state.propertyTypeFilterRefined = false
    },
    clearAllFilters: (state) => {
      state.selectedLocationAmenities = []
      state.selectedViewAmenities = []
      state.selectedAccessibility = []
      state.selectedPropertyTypes = []
      state.moreFiltersRefined = false
      state.selectedMinBeds = 0
      state.selectedMinBedrooms = 0
      state.selectedMinBathrooms = 0
      state.selectedMinPrice = undefined
      state.selectedMaxPrice = undefined
      state.shouldResetPriceRange = true
      state.selectedAmenities = []
      state.amenitiesFilterRefined = false
      state.propertyTypeFilterRefined = false
      state.searchFiltered = false
    },
    resetSearchState: (state, action: PayloadAction<SearchState>) => {
      state.numHits = null
      state.query = action.payload.query ?? ''
      state.selectedDates = action.payload.dates
      state.searchParameters.filters = getSearchFilters(
        action.payload.dates?.start || undefined,
        action.payload.dates?.end || undefined,
      )
      state.searchParameters.numericFilters = undefined
      state.selectedGuests = action.payload.range?.['Max Occupancy']?.min
      state.selectedAmenities =
        action.payload.refinementList?.['amenities.Amenities'] ?? []
      state.selectedLocationAmenities =
        action.payload.refinementList?.['amenities.Location'] ?? []
      state.selectedViewAmenities =
        action.payload.refinementList?.['amenities.View'] ?? []
      state.selectedAccessibility =
        action.payload.refinementList?.['amenities.Accessibility'] ?? []
      state.selectedPropertyTypes =
        action.payload.refinementList?.['Property Type'] ?? []
      state.selectedActivities =
        action.payload.refinementList?.['amenities.Area Activities'] ?? []
      state.selectedMinBeds = action.payload.range?.['Total Beds']?.min
      state.selectedMinBathrooms = action.payload.range?.['Bathrooms']?.min
      state.selectedMinBedrooms = action.payload.range?.['Bedrooms']?.min
      state.selectedMinPrice = action.payload.range?.['Average Per Night']?.min
      state.selectedMaxPrice = action.payload.range?.['Average Per Night']?.max
      state.gtmLocationData = initialState.gtmLocationData
      state.searchParameters.insideBoundingBox = stringifyBoundingBox(
        action.payload.boundingBox,
      )
    },
    setIpAdressLatLng: (state, action: PayloadAction<string>) => {
      state.ipAdressLatLng = action.payload
    },
    setShouldLoadGtm: (state) => {
      state.shouldLoadGtm = true
    },
    setGtmLoaded: (state) => {
      state.gtmLoaded = true
    },
    setSearchFiltersMounted: (state) => {
      state.searchFiltersMounted = true
    },
    resetSelectedDates: (state) => {
      searchSlice.caseReducers.clearAllFilters(state)
      state.shouldApplyFilters = true
    },

    updateSearchFiltersString: (
      state,
      action: PayloadAction<UpdateSearchFilterString | undefined>,
    ) => {
      const checkIn = action?.payload?.start ?? state.selectedDates?.start
      const checkOut = action?.payload?.end ?? state.selectedDates?.end

      const minPrice =
        action?.payload?.selectedMinPrice ?? state.selectedMinPrice ?? 0
      const maxPrice =
        action?.payload?.selectedMaxPrice ?? state.selectedMaxPrice ?? 0

      const [priceFilterKey, priceFilter] = buildPriceFilters(
        checkIn,
        checkOut,
        minPrice,
        maxPrice,
      )
      const updatedSearchFiltersString = getSearchFilters(checkIn, checkOut, {
        [priceFilterKey]: priceFilter,
      })

      state.searchParameters.filters = updatedSearchFiltersString
    },
  },
  extraReducers: (builder) => {
    builder.addCase(backgroundSearch.fulfilled, (state, action) => {
      state.numFilteredHits = action.payload
      state.numResults = action.payload
    })
  },
})

export default searchSlice

import { createSelector } from '@reduxjs/toolkit'

import type { AppState } from 'store/index'

import { ClearFilters } from 'hooks/useAnalytics/events/clearSearchClicked'

import {
  ClickableAttribute,
  SelectedClickableFilters,
} from 'components/Search/ClickableFilters/ClickableFilters.types'
import { ClickableAttributes } from 'components/Search/ClickableFilters/ClickableFilters.enums'

import { PETS_ALLOWED } from './search.constants'

export const selectSearchSlice = (state: AppState): AppState['search'] =>
  state.search

export const selectedPropertyTypes = createSelector(
  selectSearchSlice,
  (search) => search.selectedPropertyTypes,
)

export const allSelectedAmenities = createSelector(
  selectSearchSlice,
  ({
    selectedAccessibility,
    selectedAmenities,
    selectedLocationAmenities,
    selectedViewAmenities,
  }) => ({
    accessibility: selectedAccessibility,
    amenity: selectedAmenities,
    location: selectedLocationAmenities,
    view: selectedViewAmenities,
  }),
)

export const isFilterApply = createSelector(
  selectSearchSlice,
  (state) => state.moreFiltersRefined || state.amenitiesFilterRefined,
)

export const getSelectedFiltersCount = createSelector(
  selectSearchSlice,
  ({
    selectedMinBedrooms,
    selectedMinBeds,
    selectedMinBathrooms,
    selectedMinPrice,
    selectedMaxPrice,
    selectedPropertyTypes,
    selectedAmenities,
    selectedLocationAmenities,
    selectedViewAmenities,
    selectedAccessibility,
  }) => {
    const bedsPlusBaths = [
      selectedMinBedrooms,
      selectedMinBeds,
      selectedMinBathrooms,
      selectedMinPrice,
    ].filter(Boolean).length
    const maxPrice = selectedMaxPrice === 1000 || !selectedMaxPrice ? 0 : 1

    return (
      (bedsPlusBaths || 0) +
      (maxPrice || 0) +
      (selectedPropertyTypes?.length || 0) +
      (selectedAmenities?.length || 0) +
      (selectedLocationAmenities?.length || 0) +
      (selectedViewAmenities?.length || 0) +
      (selectedAccessibility?.length || 0)
    )
  },
)

export const isPetAllowed = createSelector(selectSearchSlice, (state) =>
  state?.selectedAccessibility?.includes(PETS_ALLOWED),
)

export const getSelectedClickableFilters = createSelector(
  (state: AppState) => state.search.selectedPropertyTypes,
  (state: AppState) => state.search.selectedAmenities,
  (state: AppState) => state.search.selectedLocationAmenities,
  (state: AppState) => state.search.selectedViewAmenities,
  (state: AppState) => state.search.selectedAccessibility,
  (
    selectedPropertyTypes,
    selectedAmenities,
    selectedLocationAmenities,
    selectedViewAmenities,
    selectedAccessibility,
  ): SelectedClickableFilters => ({
    selectedPropertyTypes,
    selectedAmenities,
    selectedLocationAmenities,
    selectedViewAmenities,
    selectedAccessibility,
  }),
)

export const getSelectedClickableFiltersArray = createSelector(
  (state: AppState) => state.search.selectedPropertyTypes,
  (state: AppState) => state.search.selectedAmenities,
  (state: AppState) => state.search.selectedLocationAmenities,
  (state: AppState) => state.search.selectedViewAmenities,
  (state: AppState) => state.search.selectedAccessibility,
  (_state: AppState, attribute: ClickableAttribute) => attribute,
  (
    selectedPropertyTypes,
    selectedAmenities,
    selectedLocationAmenities,
    selectedViewAmenities,
    selectedAccessibility,
    attribute = ClickableAttributes.ALL,
  ): string[] => {
    const clickableFiltersMap = {
      [ClickableAttributes.ALL]: [
        ...selectedAccessibility,
        ...selectedViewAmenities,
        ...selectedLocationAmenities,
        ...selectedPropertyTypes,
        ...selectedAmenities,
      ],
      [ClickableAttributes.PROPERTY_TYPE]: selectedPropertyTypes,
      [ClickableAttributes.AMENITIES]: selectedAmenities,
      [ClickableAttributes.MORE_FILTERS]: [
        ...selectedAccessibility,
        ...selectedViewAmenities,
        ...selectedLocationAmenities,
      ],
    }
    return clickableFiltersMap[attribute]
  },
)

export const selectedFiltersNames = createSelector(
  (state: AppState) => state.search.selectedAmenities,
  (state: AppState) => state.search.selectedAccessibility,
  (state: AppState) => state.search.selectedViewAmenities,
  (state: AppState) => state.search.selectedLocationAmenities,
  (state: AppState) => state.search.selectedPropertyTypes,
  (state: AppState) => state.search.selectedMinBathrooms,
  (state: AppState) => state.search.selectedMinBeds,
  (state: AppState) => state.search.selectedMinBedrooms,
  (state: AppState) => state.search.selectedMinPrice,
  (state: AppState) => state.search.selectedMaxPrice,
  (
    selectedAmenities,
    selectedAccessibility,
    selectedViewAmenities,
    selectedLocationAmenities,
    selectedPropertyTypes,

    selectedMinBathrooms,
    selectedMinBeds,
    selectedMinBedrooms,
    selectedMinPrice,
    selectedMaxPrice,
  ) => {
    const selectedFilters: ClearFilters = {}
    const amenities = [
      ...selectedViewAmenities,
      ...selectedAccessibility,
      ...selectedLocationAmenities,
      ...selectedAmenities,
    ]
    if (amenities.length > 0) selectedFilters.amenities = amenities
    if (selectedPropertyTypes.length > 0)
      selectedFilters.propertyType = selectedPropertyTypes
    if (Boolean(selectedMinBathrooms))
      selectedFilters.bathrooms = selectedMinBathrooms
    if (Boolean(selectedMinBeds)) selectedFilters.beds = selectedMinBeds
    if (Boolean(selectedMinBedrooms))
      selectedFilters.bedrooms = selectedMinBedrooms
    if (Boolean(selectedMinPrice)) selectedFilters.priceMin = selectedMinPrice
    if (Number(selectedMaxPrice) !== 1000)
      selectedFilters.priceMax = selectedMaxPrice

    return selectedFilters
  },
)

import React, { createContext, useCallback, useReducer, useState } from 'react'
import some from 'lodash/some'

import { getDismissedBanners, markBannerAsDismissed } from 'utils/localStorage'

type BannerContextState = {
  dismissedBanners: {
    [key: string]: boolean
  }
  mountedBanners: {
    [key: string]: boolean
  }
}

const getInitialBannerState = (): BannerContextState => {
  return {
    dismissedBanners: getDismissedBanners(),
    mountedBanners: {},
  }
}

const initialBannerState = getInitialBannerState()

type DismissBannerAction = {
  type: 'dismiss_banner'
  payload: string
}

type BannerMountedAction = {
  type: 'banner_mounted'
  payload: string
}

type BannerUnmountedAction = {
  type: 'banner_unmounted'
  payload: string
}

type BannerAction =
  | DismissBannerAction
  | BannerMountedAction
  | BannerUnmountedAction

const bannerReducer = (
  state: BannerContextState,
  action: BannerAction,
): BannerContextState => {
  switch (action.type) {
    case 'dismiss_banner': {
      const bannerId = action.payload
      markBannerAsDismissed(bannerId)
      return {
        ...state,
        dismissedBanners: {
          ...state.dismissedBanners,
          [bannerId]: true,
        },
      }
    }
    case 'banner_mounted': {
      return {
        ...state,
        mountedBanners: {
          ...state.mountedBanners,
          [action.payload]: true,
        },
      }
    }
    case 'banner_unmounted': {
      return {
        ...state,
        mountedBanners: {
          ...state.mountedBanners,
          [action.payload]: false,
        },
      }
    }
    default: {
      return state
    }
  }
}

type BannerContextType = {
  bannerEl: HTMLElement | null
  setBannerEl: (ref: HTMLElement | null) => void
  getIsBannerOpen: (bannerId: string) => boolean
  dispatch: (action: BannerAction) => void
}

const BannerContext = createContext<BannerContextType>({
  bannerEl: null,
  setBannerEl: () => {},
  getIsBannerOpen: () => false,
  dispatch: () => {},
})

type BannerContextProps = {
  children?: React.ReactNode
}

const BannerContextProvider: React.FC<BannerContextProps> = ({ children }) => {
  const [bannerEl, setBannerEl] = useState<HTMLElement | null>(null)
  const [{ mountedBanners, dismissedBanners }, dispatch] = useReducer(
    bannerReducer,
    initialBannerState,
    getInitialBannerState,
  )

  const getIsBannerOpen = useCallback(
    (bannerId: string) => {
      const isBannerMounted = mountedBanners[bannerId]
      const isBannerDismissed = dismissedBanners[bannerId]

      return !isBannerDismissed && isBannerMounted
    },
    [dismissedBanners, mountedBanners],
  )

  return (
    <BannerContext.Provider
      value={{
        bannerEl,
        setBannerEl,
        getIsBannerOpen,
        dispatch,
      }}
    >
      {children}
    </BannerContext.Provider>
  )
}

export { BannerContext, BannerContextProvider }

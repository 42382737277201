/**
 * getNestedValue
 * Returns the first position of an array or the value if it's primitive
 * @param  {T|T[]} value - A primitive or an array of primitives
 * @returns T - The primitive or the first position of the primitive
 * @example - getNestedValue(1) // returns 1
 * @example - getNestedValue([2, 3, 4]) // returns 2
 */
function getNestedValue<T>(value: T | T[]): T {
  return Array.isArray(value) ? value[0] : value
}

export default getNestedValue

import { useEffect } from 'react'

import { identifyUser } from 'lib/amplitude'

const useAmplitude = () => {
  useEffect(() => {
    const intervalId = setInterval(async () => {
      const anonymousId = localStorage.getItem('ajs_anonymous_id')

      if (anonymousId) {
        try {
          await identifyUser()
          clearInterval(intervalId)
        } catch (error) {
          console.error('Error identifying user:', error)
        }
      }
    }, 100)

    return () => clearInterval(intervalId)
  }, [])
}

export default useAmplitude

export const AVAILABLE = 'DC'
export const STAY_OR_CHECK_OUT = 'DO'
export const STAY_OR_CHECK_IN = 'DI'
export const STAY_ONLY = 'DX'
export const ONLY_CHECK_IN_OR_OUT = 'NC'
export const ONLY_CHECK_IN = 'NI'
export const ONLY_CHECK_OUT = 'NO'
export const NOT_AVAILABLE = 'NX'

const availabilityCodes = {
  AVAILABLE,
  STAY_OR_CHECK_OUT,
  STAY_OR_CHECK_IN,
  STAY_ONLY,
  ONLY_CHECK_IN_OR_OUT,
  ONLY_CHECK_IN,
  ONLY_CHECK_OUT,
  NOT_AVAILABLE,
}

export default availabilityCodes

import {
  GrowthBook,
  GrowthBookProvider as GBP,
  useFeatureIsOn,
} from '@growthbook/growthbook-react'
import { createContext, ReactNode, useContext, useEffect } from 'react'

import experimentViewed from 'hooks/useAnalytics/events/experimentViewed'

import getAnonymousId from 'utils/getAnonymousId'
import { getExperimentViewed, markExperimentAsViewed } from 'utils/localStorage'

import { FEATURE_FLAGS } from 'constants/feature-flags'

const growthbook = new GrowthBook({
  apiHost: process.env.NEXT_PUBLIC_FEATURE_TOGGLE_API_HOST,
  clientKey: process.env.NEXT_PUBLIC_FEATURE_TOGGLE_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV !== 'production',
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    const value = getExperimentViewed(experiment.key)

    const hasLocalStorageValue = !!value
    const isSameVariant = hasLocalStorageValue && value === result.key

    if (!hasLocalStorageValue || !isSameVariant) {
      experimentViewed({
        experimentId: experiment.key,
        variationId: result.key,
      })

      markExperimentAsViewed(experiment.key, result.key)

      // eslint-disable-next-line no-console
      console.log('Viewed Experiment', {
        experimentId: experiment.key,
        variationId: result.key,
        anonymousId: getAnonymousId(),
      })
    }
  },
})

const ExperimentTracker = () => {
  return null
}

const GrowthBookContext = createContext<GrowthBook | undefined>(undefined)

export const useGrowthBook = () => {
  const context = useContext(GrowthBookContext)

  if (!context) {
    throw new Error('useGrowthBook must be used within a GrowthBookProvider')
  }

  return context
}

const GrowthBookProvider = ({ children }: { children: ReactNode }) => {
  useEffect(() => {
    if (!growthbook.getAttributes().anonymous_id) {
      const anonymousId = getAnonymousId()
      growthbook.setAttributes({
        ...growthbook.getAttributes(),
        anonymous_id: anonymousId,
      })
    }

    growthbook.loadFeatures()
  }, [])

  return (
    <GrowthBookContext.Provider value={growthbook}>
      <GBP growthbook={growthbook}>
        <>
          <ExperimentTracker />
          {children}
        </>
      </GBP>
    </GrowthBookContext.Provider>
  )
}

export default GrowthBookProvider

import dayjs from 'dayjs'

import { GetAndFilterPetsAllowed } from './search.types'
import { NO_PETS_ALLOWED, PETS_ALLOWED } from './search.constants'

import {
  NestedLogicFilter,
  NestedNumericFilter,
  NumericFilter,
} from 'config/AlgoliaClient'
import { filterBuilder } from 'config/AlgoliaClient/AlgoliaClient.utils'

import urlToSearchState from 'utils/search/urlToSearchState'
import getLOSNights from 'utils/strings/getLOSNights'

import availabilityCodes from 'constants/availableCodes'
import dateFormats from 'constants/dates'
import type { NumericAttributeName } from 'constants/filterAttributes'

export const stringifyBoundingBox = (
  boundingBox:
    | {
        northEast: { lat: string; lng: string }
        southWest: { lat: string; lng: string }
      }
    | undefined,
) =>
  boundingBox
    ? ([
        [
          boundingBox.northEast.lat,
          boundingBox.northEast.lng,
          boundingBox.southWest.lat,
          boundingBox.southWest.lng,
        ],
      ].join() as any)
    : undefined

export const searchStateFromUrl = process.browser
  ? urlToSearchState(window.location.search)
  : undefined

export const buildAvailabilityFilters = (
  startDate: string | undefined,
  endDate: string | undefined,
): NestedLogicFilter | undefined => {
  if (!startDate || !endDate) return undefined

  let availability: NestedLogicFilter = {}
  const checkInDate = dayjs(startDate, dateFormats.DEFAULT)
  const checkOutDate = dayjs(endDate, dateFormats.DEFAULT)
  const rangeLength = checkOutDate.diff(startDate, 'days')
  const formattedCheckIn = startDate
  const formattedCheckOut = endDate

  // add start date availability
  availability = {
    ...availability,
    [formattedCheckIn]: {
      join: 'OR',
      value: [availabilityCodes.AVAILABLE, availabilityCodes.STAY_OR_CHECK_IN],
    },
  }

  // add availability for any days in between start and end
  for (let i = 1; i < rangeLength; i++) {
    const nextDay = checkInDate.add(i, 'days').format(dateFormats.DEFAULT)
    availability = {
      ...availability,
      [nextDay]: {
        join: 'OR',
        value: [availabilityCodes.AVAILABLE, availabilityCodes.STAY_ONLY],
      },
    }
  }

  // add end date availability
  availability = {
    ...availability,
    [formattedCheckOut]: {
      join: 'OR',
      value: [
        availabilityCodes.AVAILABLE,
        availabilityCodes.STAY_OR_CHECK_OUT,
        availabilityCodes.ONLY_CHECK_OUT,
      ],
    },
  }

  return availability
}

export const buildMinimumStayFilters = (
  startDate: string | undefined,
  endDate: string | undefined,
): NestedNumericFilter | undefined => {
  if (!startDate || !endDate) return undefined

  let filters: NestedNumericFilter = {}
  const checkInDate = dayjs(startDate, dateFormats.DEFAULT)
  const checkOutDate = dayjs(endDate, dateFormats.DEFAULT)
  const rangeLength = checkOutDate.diff(startDate, 'days')
  const formattedCheckIn = startDate

  filters = {
    ...filters,
    [formattedCheckIn]: {
      lte: rangeLength,
    },
  }

  // add between check-in/out
  if (rangeLength > 1) {
    let daysInBetween = rangeLength - 1
    let dateBefore = checkInDate.clone()
    while (daysInBetween > 0) {
      const nextDate = dateBefore.add(1, 'day')
      const nextDateFormatted = nextDate.format(dateFormats.DEFAULT)
      filters = {
        ...filters,
        [nextDateFormatted]: {
          lte: rangeLength,
        },
      }
      dateBefore = nextDate.clone()
      daysInBetween -= 1
    }
  }

  return filters
}

export const buildPriceFilters = (
  startDate: string | undefined,
  endDate: string | undefined,
  minPrice: number,
  maxPrice: number,
  cutoffLongerStays: boolean = true,
): [NumericAttributeName, NumericFilter | NestedNumericFilter] => {
  const aThousand = 1000
  const totalNights = getLOSNights(startDate, endDate, cutoffLongerStays)
  const stayMoreThanAMonth = totalNights > 35
  let priceFilterFacet: NumericAttributeName
  let priceFilterPath
  if ((!startDate && !endDate) || stayMoreThanAMonth) {
    priceFilterFacet = 'avgPerNight'
  } else {
    priceFilterFacet = 'avgPrices'
    priceFilterPath = `${startDate}.${totalNights}`
  }

  const minPriceFilter: NumericFilter = minPrice ? { gte: minPrice } : {}

  const maxPriceFilter: NumericFilter =
    maxPrice && maxPrice < aThousand
      ? {
          lte: maxPrice,
        }
      : {}

  const priceFilter: NumericFilter | NestedNumericFilter = priceFilterPath
    ? {
        [priceFilterPath]: {
          ...minPriceFilter,
          ...maxPriceFilter,
        },
      }
    : {
        ...minPriceFilter,
        ...maxPriceFilter,
      }

  return [priceFilterFacet, priceFilter]
}

export const buildMaxOccupancyFilter = (
  maxOccupancy: number | undefined = 0,
): NumericFilter | undefined => {
  if (!maxOccupancy) return

  const maxOccupancyFilter: NumericFilter = {
    gte: maxOccupancy,
    lte: 99,
  }

  return maxOccupancyFilter
}

type GetFilterString = (
  checkIn?: string,
  checkOut?: string,
  extraFilteringValues?: object,
) => string

export const getSearchFilters: GetFilterString = (
  checkIn?,
  checkOut?,
  extraFilteringValues = {},
) => {
  if (!checkIn || !checkOut) return ''

  const availability = buildAvailabilityFilters(checkIn, checkOut)
  const minStay = buildMinimumStayFilters(checkIn, checkOut)
  return filterBuilder({
    availability,
    minStay,
    ...extraFilteringValues,
  })
}

export const getAccessibilitesByPetsAllowed: GetAndFilterPetsAllowed = (
  filters,
) => {
  const { accessibilities, isPetAllowed } = filters
  const newAccessibilties = [...accessibilities]

  if (isPetAllowed === PETS_ALLOWED) {
    const indexOfValue = newAccessibilties.indexOf(NO_PETS_ALLOWED)
    if (indexOfValue !== -1) newAccessibilties.splice(indexOfValue, 1)
  }

  if (isPetAllowed === NO_PETS_ALLOWED) {
    const indexOfValue = newAccessibilties.indexOf(PETS_ALLOWED)
    if (indexOfValue !== -1) newAccessibilties.splice(indexOfValue, 1)
  }

  return newAccessibilties
}

import React, { useEffect } from 'react'
import { ConsentManagerBuilder } from '@segment/consent-manager'

import ConsentContextProvider from 'context/ConsentContext'

import { CUSTOM_DESTINATIONS } from './ConsentConfig'
import CookieConsentModal from './CookieConsentModal'
import CookieConsentPreferencesModal from './CookieConsentPreferencesModal'
import CookieManager from './CookieManager'

interface SegmentCookieConsentBuilderProps {
  setSegmentError: (error: string) => void
}

const SegmentCookieConsentBuilder: React.FC<
  SegmentCookieConsentBuilderProps
> = ({ setSegmentError }) => {
  useEffect(() => {
    // @ts-ignore
    window.analytics.addSourceMiddleware(({ payload, next, integrations }) => {
      payload.obj.integrations['All'] = true
      next(payload)
    })
  }, [])

  return (
    <ConsentManagerBuilder
      onError={(err) => {
        setSegmentError(err.message)
      }}
      shouldRequireConsent={() => true}
      writeKey={process.env.NEXT_PUBLIC_SEGMENT_JS_API_KEY!}
    >
      {({
        destinationPreferences,
        destinations,
        preferences,
        saveConsent,
        setPreferences,
      }) => {
        const allDestinations = [...destinations, ...CUSTOM_DESTINATIONS]
        const showConsentModal =
          destinationPreferences &&
          typeof destinationPreferences.custom_advertising === 'undefined' &&
          typeof destinationPreferences.custom_analytics === 'undefined' &&
          typeof destinationPreferences.custom_functional === 'undefined'

        const consentValues = {
          advertising: Boolean(preferences.custom_advertising) || false,
          analytics: Boolean(preferences.custom_analytics) || false,
          functional: Boolean(preferences.custom_functional) || false,
          showConsentModal,
        }

        return (
          <ConsentContextProvider {...consentValues}>
            <CookieManager />
            <CookieConsentModal
              {...{
                allDestinations,
                setPreferences,
                saveConsent,
              }}
            />
            <CookieConsentPreferencesModal
              {...{
                destinations,
                customDestinations: CUSTOM_DESTINATIONS,
                preferences,
                setPreferences,
                saveConsent,
              }}
            />
          </ConsentContextProvider>
        )
      }}
    </ConsentManagerBuilder>
  )
}

export default SegmentCookieConsentBuilder

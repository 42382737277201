import { getIsOnServerSide } from 'utils/validations'

export default function getIsRobot(userAgent?: string) {
  const userAgentRegex = new RegExp(
    '(bot|crawler|spider|crawling|curl|wget|google|facebook|bing|msn|duckduckbot|teoma|slurp|yandex|phantomjs|selenium|prerender)',
    'i',
  )

  const browserUserAgent = getIsOnServerSide() ? '' : window.navigator.userAgent

  return userAgentRegex.test(userAgent || browserUserAgent)
}

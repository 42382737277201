import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import dayjs from 'dayjs'

import kountSDK from 'components/scripts/kount-web-client-sdk'

import { isOnServerSide } from 'utils/validations'
import { getNewKountSessionId } from 'utils/kountUtils'

import { kountClientId } from 'constants/globalEnv'

export const SESSION_ID_STORAGE_KEY = 'evolveKountSessionId'
export const MAX_SESSION_AGE_DAYS = 7
// Expire the token if the user hasn't used the site in this many days
export const MAX_INACTIVITY_DAYS = 3
export const LAST_USED_DATE_KEY = 'evolveKountLastUsedDate'
export const CREATED_DATE_KEY = 'evolveKountCreatedDate'
export const KOUNT_CONFIG = {
  clientID: kountClientId,
  environment: process.env.NODE_ENV === 'production' ? 'PROD' : 'TEST',
  isSinglePageApp: true,
}

type KountContextType = {
  sessionId: string
  initNewSessionId: () => void
}

const KountContext = createContext<KountContextType>({
  sessionId: '',
  initNewSessionId: () => {},
})

type KountContextProps = {
  children?: React.ReactNode
}

const KountContextProvider: React.FC<KountContextProps> = ({ children }) => {
  const [sessionId, setSessionId] = useState(
    !isOnServerSide ? localStorage.getItem(SESSION_ID_STORAGE_KEY) ?? '' : '',
  )
  const [lastUsedDate, setLastUsedDate] = useState(
    dayjs(
      !isOnServerSide ? localStorage.getItem(LAST_USED_DATE_KEY) ?? '' : '',
    ),
  )
  const [createdDate, setCreatedDate] = useState(
    dayjs(!isOnServerSide ? localStorage.getItem(CREATED_DATE_KEY) ?? '' : ''),
  )

  const sessionHasExpired = useMemo(
    () => lastUsedDate.isBefore(dayjs().subtract(MAX_INACTIVITY_DAYS, 'days')),
    [lastUsedDate],
  )

  const sessionIsStale = useMemo(
    () => createdDate.isBefore(dayjs().subtract(MAX_SESSION_AGE_DAYS, 'days')),
    [createdDate],
  )

  const initNewSessionId = useCallback(() => {
    if (isOnServerSide) return

    const newSessionId = getNewKountSessionId()
    const newCreatedDate = dayjs()
    const newLastUsedDate = dayjs()

    setSessionId(newSessionId)
    setCreatedDate(newCreatedDate)
    setLastUsedDate(newLastUsedDate)

    localStorage.setItem(SESSION_ID_STORAGE_KEY, newSessionId)
    localStorage.setItem(CREATED_DATE_KEY, newCreatedDate.toISOString())
    localStorage.setItem(LAST_USED_DATE_KEY, newLastUsedDate.toISOString())
  }, [])

  useEffect(() => {
    if (isOnServerSide) return

    if (!sessionId || sessionHasExpired || sessionIsStale) {
      initNewSessionId()
    }
  }, [initNewSessionId, sessionHasExpired, sessionId, sessionIsStale])

  useEffect(() => {
    if (isOnServerSide) return

    if (sessionId) {
      kountSDK(KOUNT_CONFIG, sessionId)
    }
  }, [sessionId])

  return (
    <KountContext.Provider
      value={{
        sessionId,
        initNewSessionId,
      }}
    >
      {children}
    </KountContext.Provider>
  )
}

export { KountContext, KountContextProvider }

import stringify from 'qs/lib/stringify'
import type { SearchState } from 'react-instantsearch-core'
import type { NextRouter } from 'next/router'

import { SearchMetaState } from 'store/search/search.types'

import type { IRecentSearch } from 'hooks/useRecentSearches'

import { urlPrettySpaces, normalizeSearchQuery } from './Strings'
import getNestedValue from './getNestedValue'
import { getTotalNights } from './Listings'

import type { GeocoderQueryAndResult } from '../types/externalData'

import { EXPANDED_DATES_SUGGESTION } from 'constants/search'

type GroupedSearch = {
  [index: string]: IRecentSearch[]
}

export const sanitizeQueryParams = (state: SearchState) => {
  // parameters to be stored in the URL
  const queryParameters: {
    [index: string]: any
  } = {}

  // set search query text
  if (state.query) {
    queryParameters.query = normalizeSearchQuery(state.query)
  } else {
    // Convert city/state/country to query
    let keywords: string[] = []
    if (state.city) {
      keywords = [...keywords, state.city as string]
    }

    if (state.state) {
      keywords = [...keywords, state.state as string]
    }

    if (state.country) {
      keywords = [...keywords, state.country as string]
    }

    if (keywords.length) {
      queryParameters.query = normalizeSearchQuery(keywords.join(', '))
    }
  }

  // set page
  if (state.page && state.page !== 1) {
    queryParameters.page = state.page
  }

  // set guests
  if (state.guests && Object.keys(state.guests).length) {
    for (const key in state.guests) {
      if (state.guests[key] && key !== 'total' && state.guests[key] !== '0') {
        queryParameters[key] = state.guests[key]
      }
    }
  }

  // set dates
  if (state.dates && Object.keys(state.dates).length) {
    if (state.dates.start) {
      queryParameters.startDate = state.dates.start
    }
    if (state.dates.end) {
      queryParameters.endDate = state.dates.end
    }
  }

  // set bedrooms
  if (state.Bedrooms) {
    queryParameters.minBedrooms = state.Bedrooms.min
  }

  // set bounding box
  if (state.boundingBox && Object.keys(state.boundingBox).length) {
    const boundingBox: {
      [index: string]: any
    } = {}

    for (const key in state.boundingBox) {
      if (state.boundingBox[key] && state.boundingBox[key] !== '') {
        boundingBox[key] = state.boundingBox[key]
      }
    }

    if (Object.keys(boundingBox).length) {
      queryParameters['boundingBox'] = boundingBox
    }
  }

  // set range filters
  if (state.range && Object.keys(state.range).length) {
    for (const key in state.range) {
      switch (key) {
        case 'Total Beds':
          if (
            'min' in state.range[key] &&
            typeof state.range[key]['min'] !== 'undefined'
          ) {
            queryParameters['minBeds'] = state.range[key]['min']
          }
          if (
            'max' in state.range[key] &&
            typeof state.range[key]['max'] !== undefined
          ) {
            queryParameters['maxBeds'] = state.range[key]['max']
          }
          break
        case 'Max Occupancy':
          // Max Occupancy is handled by the `guests` property
          break
        default:
          // eslint-disable-next-line no-case-declarations
          const val = state.range[key]

          if (key.includes('LOS_PriceAverages')) {
            const val = state.range[key]
            if ('min' in val && typeof val['min'] !== undefined) {
              queryParameters[`minLOS_PriceAverages`] = val['min']
            }

            if ('max' in val && typeof val['max'] !== undefined) {
              queryParameters[`maxLOS_PriceAverages`] = val['max']
            }
          } else {
            if ('min' in val && typeof val['min'] !== undefined) {
              queryParameters[`min${urlPrettySpaces(key)}`] = val['min']
            }

            if ('max' in val && typeof val['max'] !== undefined) {
              queryParameters[`max${urlPrettySpaces(key)}`] = val['max']
            }
          }
      }
    }
  }
  // set refinement list filters
  if (state.refinementList) {
    for (const key in state.refinementList) {
      if (Array.isArray(state.refinementList[key]))
        queryParameters[urlPrettySpaces(key)] =
          state.refinementList[key]?.map(urlPrettySpaces)
    }
  }

  // set listing channel to track traffic source
  if (state.lc) {
    queryParameters.lc = getNestedValue(state.lc)
  }

  // set sessionid to track traffic source identifier

  if (state.sessionid) {
    queryParameters.sessionid = getNestedValue(state.sessionid)
  }

  // set utm_source to track traffic source
  if (state.utm_source) {
    queryParameters.utm_source = getNestedValue(state.utm_source)
  }

  // set utm_medium to track traffic source
  if (state.utm_medium) {
    queryParameters.utm_medium = getNestedValue(state.utm_medium)
  }

  // set utm_campaign to track traffic source
  if (state.utm_campaign) {
    queryParameters.utm_campaign = getNestedValue(state.utm_campaign)
  }

  // set utm_content to track traffic source
  if (state.utm_content) {
    queryParameters.utm_content = getNestedValue(state.utm_content)
  }

  // set utm_term to track traffic source
  if (state.utm_term) {
    queryParameters.utm_term = getNestedValue(state.utm_term)
  }

  // set clickID to track traffic click identifier
  if (state.clickId) {
    queryParameters.clickId = getNestedValue(state.clickId)
  }

  // set clickID to track traffic device type
  if (state.deviceType) {
    queryParameters.deviceType = getNestedValue(state.deviceType)
  }

  // set clickID to track traffic market
  if (state.market) {
    queryParameters.market = getNestedValue(state.market)
  }

  // set queryID to preserve for Algolia analytics
  if (state.queryID) {
    queryParameters.queryID = state.queryID
  }

  // set inquiry modal open parameter for listing detail ask a question
  if (state.inquiry_modal) {
    queryParameters['inquiry_modal'] = state.inquiry_modal
  }

  // set listing id parameter for booking flow
  if (state.id) {
    queryParameters['listingId'] = state.id
  }

  // set initiated_from (if)
  if (state.if) {
    queryParameters.if = state.if
  }

  // set prevListingPage
  if (state.prevListingPage) {
    queryParameters.prevListingPage = state.prevListingPage
  }

  return queryParameters
}

// build search URLs
export const createURL = (state: SearchState): string => {
  const isDefaultRoute =
    !state ||
    ((!state.query || state.query === '') &&
      (!state.page || state.page === 1) &&
      (!state.boundingBox || !Object.keys(state.boundingBox).length) &&
      (!state.guests || !Object.keys(state.guests).length) &&
      (!state.dates || !Object.keys(state.dates).length) &&
      (!state.range || !Object.keys(state.range).length) &&
      (!state.refinementList || !Object.keys(state.refinementList).length) &&
      (!state.inquiry_modal || state.inquiry_modal === '') &&
      !state.queryID)

  if (isDefaultRoute) {
    return ''
  }

  const queryParameters = sanitizeQueryParams(state)

  const queryString = stringify(queryParameters, {
    arrayFormat: 'repeat',
  })

  return `${queryString ? '?' + queryString : ''}`
}

export const searchStateToUrl = (searchState: SearchState): string => {
  return createURL(searchState)
}

export const removeAvailabilityFilters = (filters?: string): string => {
  if (filters) {
    // remove availability filters but retain any other filters
    const newFilters: string[] = []
    const filterParts = filters.split(' AND ')

    for (let i = 0; i < filterParts.length; i++) {
      const filterPart = filterParts[i]

      if (
        !filterPart.includes('Availability') &&
        !filterPart.includes('MinStay')
      ) {
        newFilters.push(filterPart)
      }
    }

    const joinedFilters = newFilters.join(' AND ')

    return joinedFilters
  }

  return ''
}

export const formatGtmLocationData = (
  geocoderData: GeocoderQueryAndResult | Record<string, never>,
) => {
  const locationData = {
    city: '',
    region: '',
    country: '',
  }

  const { query, address_components, types, formatted_address } = geocoderData

  const noZipCodeAddress = formatted_address.replace(/\s\d+/, '')

  // Find the address_component that matches the geocoder result type
  const matchedLocation = address_components?.find(
    (comp) => comp.types[0] === types[0],
  )

  if (query === noZipCodeAddress || noZipCodeAddress.includes(query)) {
    // Handle selection from locations dropdown
    noZipCodeAddress.split(',').forEach((addressPart) => {
      let formattedPart = addressPart.trim()
      if (formattedPart === 'USA') {
        formattedPart = 'US'
      }

      const matchedComponent = address_components?.find(
        (comp) =>
          comp.short_name === formattedPart || comp.long_name === formattedPart,
      )

      if (matchedComponent?.types[0] === 'locality') {
        locationData.city = matchedComponent.long_name
      }
      if (matchedComponent?.types[0] === 'administrative_area_level_1') {
        locationData.region = matchedComponent.long_name
      }
      if (matchedComponent?.types[0] === 'country') {
        locationData.country = matchedComponent.long_name
      }
    })
  } else if (matchedLocation) {
    if (
      // Match valid query typed into searchbox
      query.toLowerCase().replace(' ', '') ===
      matchedLocation.long_name.toLowerCase().replace(' ', '')
    ) {
      if (types[0] === 'locality') {
        locationData.city = matchedLocation.long_name
      }
      if (types[0] === 'administrative_area_level_1') {
        locationData.region = matchedLocation.long_name
      }
      if (types[0] === 'country') {
        locationData.country = matchedLocation.long_name
      }
    } else {
      // matchedLocation was found but it doesn't match the search query's format
      address_components.forEach((comp) => {
        if (comp?.types[0] === 'locality') {
          locationData.city = comp.long_name
        }
        if (comp?.types[0] === 'administrative_area_level_1') {
          locationData.region = comp.long_name
        }
        if (comp?.types[0] === 'country') {
          locationData.country = comp.long_name
        }
      })
    }
  }

  return locationData
}

export const filterRecentSearches = (searches: IRecentSearch[]) => {
  let filteredSearches: IRecentSearch[] = []

  if (searches.length) {
    // Group multiple searches with same base query
    const groupedSearches: GroupedSearch = searches.reduce(
      (searchGroups: GroupedSearch, search) => ({
        ...searchGroups,
        [search.query as string]: [
          ...(searchGroups[search.query as string] || []),
          search,
        ],
      }),
      {},
    )

    Object.keys(groupedSearches).forEach((key) => {
      const group = groupedSearches[key]
      // Always show unique searches
      if (group.length === 1) {
        filteredSearches = [...filteredSearches, group[0]]
      } else {
        // Always show search from group with the most parameters
        const preferredSearch = group.reduce(
          (prev: IRecentSearch, curr: IRecentSearch) => {
            return Object.keys(prev).length > Object.keys(curr).length
              ? prev
              : curr
          },
        )
        filteredSearches = [...filteredSearches, preferredSearch]
        // Only show search with same base query as preferredSearch if dates or adults are different,
        // and there is no search showing with the same number of adults
        const adultCount: string[] = []
        group.forEach((search: IRecentSearch) => {
          const sameDates =
            search.data.startDate === preferredSearch.data.startDate &&
            search.data.endDate === preferredSearch.data.endDate
          const adultCountAdded = adultCount.includes(
            search.data.adults as string,
          )
          if (
            (!sameDates && !adultCountAdded) ||
            (sameDates &&
              search.data.adults !== preferredSearch.data.adults &&
              !adultCountAdded)
          ) {
            adultCount.push(search.data.adults as string)
            filteredSearches = [...filteredSearches, search]
          }
        })
      }
    })
  }

  return filteredSearches
}

type GetResultsSugestions = (
  selectedDates: SearchMetaState['selectedDates'],
) => string | undefined

export const getResultSuggestions: GetResultsSugestions = (selectedDates) => {
  if (!selectedDates) return

  const { start, end } = selectedDates
  const nightsBetweenDates = getTotalNights(start, end)

  if (nightsBetweenDates !== 1) return

  return EXPANDED_DATES_SUGGESTION
}

type RemoveQueryParams = (
  deletingParams: string[],
  router: NextRouter,
) => void | undefined

export const removeQueryParams: RemoveQueryParams = (
  deletingParams,
  router,
) => {
  const { asPath, pathname, replace } = router
  const queryString: string = asPath.split('?')[1]
  const urlParamsObject = new URLSearchParams(queryString)
  deletingParams.forEach((param) => urlParamsObject.delete(param))
  replace({ pathname, query: urlParamsObject.toString() }, undefined, {
    shallow: true,
  })
}

export type UpdatePetsInAccessilityFunc = (
  urlObject: Partial<SearchState>,
) => Partial<SearchState>

export const filterAccessibilitiesInQueryObject: UpdatePetsInAccessilityFunc = (
  urlObject,
) => {
  const { 'amenities.Accessibility': accessibilities, pets } = urlObject
  const accessibilitiesCopy = [...(accessibilities ?? [])]
  const petsAllowedIndex = accessibilitiesCopy.indexOf('Pets-Allowed')

  if (pets && petsAllowedIndex === -1) accessibilitiesCopy.push('Pets-Allowed')

  if (!pets && accessibilitiesCopy.length && petsAllowedIndex !== -1)
    accessibilitiesCopy.splice(petsAllowedIndex, 1)

  return {
    ...urlObject,
    'amenities.Accessibility': accessibilitiesCopy,
  }
}

import { trackOnServer } from 'hooks/useAnalytics/AnalyticsTransmitter'

export type ExperimentViewed = {
  experimentId: string
  variationId: string
}

export default function experimentViewed(data: ExperimentViewed) {
  const { experimentId, variationId, ...rest } = data

  trackOnServer('Experiment Viewed', {
    ...data,
  })
}

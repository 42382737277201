import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface SearchDataState {
  totalHits: number | null
  activeMarker: any
  detailPageUrl: string
}

const searchDataSlice = createSlice({
  name: 'searchData',
  initialState: {
    totalHits: null,
    activeMarker: null,
    detailPageUrl: '',
  } as SearchDataState,
  reducers: {
    setTotalHits: (state, action: PayloadAction<number>) => {
      state.totalHits = action.payload
    },
    setActiveMarker: (state, action) => {
      state.activeMarker = action.payload
    },
    setDetailPageUrl: (state, action: PayloadAction<string>) => {
      state.detailPageUrl = action.payload
    },
  },
})

export const { setTotalHits, setActiveMarker, setDetailPageUrl } =
  searchDataSlice.actions

export default searchDataSlice

const DEFAULT = 'YYYYMMDD'
const SPLIT_BY_DASH = 'YYYY-MM-DD'
const MONTH_NAME_LARGE_FORMAT = 'MMMM D, YYYY'
const MONTH_DAY = 'MMM DD'
const HOUR_MINUTES = 'h:mm A'

const DatesConstants = {
  DEFAULT,
  SPLIT_BY_DASH,
  MONTH_NAME_LARGE_FORMAT,
  MONTH_DAY,
  HOUR_MINUTES,
}

export default DatesConstants

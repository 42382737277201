import React from 'react'

import { getTrackingPreferencesCookies } from 'utils/getCookies'

type SegmentConsentManager =
  React.FunctionComponent<React.PropsWithChildren> & {
    Functional: React.FC<React.PropsWithChildren>
    Analytics: React.FC<React.PropsWithChildren>
    Advertising: React.FC<React.PropsWithChildren>
  }

const SegmentConsentManager: SegmentConsentManager = ({ children }) => {
  return <>{children}</>
}

const Functional: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { functional } = getTrackingPreferencesCookies()
  return <>{functional ? children : null}</>
}
const Analytics: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { analytics } = getTrackingPreferencesCookies()
  return <>{analytics ? children : null}</>
}
const Advertising: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { advertising } = getTrackingPreferencesCookies()
  return <>{advertising ? children : null}</>
}

SegmentConsentManager.Functional = Functional
SegmentConsentManager.Analytics = Analytics
SegmentConsentManager.Advertising = Advertising

export default SegmentConsentManager

import Script from 'next/script'

import { googleMapsApiKey } from 'constants/globalEnv'

const GoogleMapAPI = () => {
  return (
    <Script
      src={`https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places&language=en`}
      strategy="beforeInteractive"
    />
  )
}

export default GoogleMapAPI
